/**
 * An object mapping from shape ID to diocese or province ID
 * (Generated from mapping csv file using a script. See /scripts folder README.)
 */
export const SHAPE_ID_MAP = {
    S0001: 'D054',
    S0002: 'D077',
    S0003: 'D177',
    S0004: 'D188',
    S0005: 'D180',
    S0006: 'D490',
    S0007: 'D244',
    S0008: 'D107',
    S0009: 'D019',
    S0010: 'D073',
    S0011: 'D004',
    S0012: 'D261',
    S0013: 'D260',
    S0014: 'D240',
    S0015: 'D484',
    S0016: 'D068',
    S0017: 'D175',
    S0018: 'D242',
    S0019: 'D063',
    S0020: 'D235',
    S0021: 'D118',
    S0022: 'D705',
    S0023: 'D052',
    S0024: 'D081',
    S0025: 'D470',
    S0026: 'D472',
    S0027: 'D473',
    S0028: 'D475',
    S0029: 'D614',
    S0030: 'D477',
    S0031: 'D479',
    S0032: 'D481',
    S0033: 'D482',
    S0034: 'D483',
    S0035: 'D484',
    S0036: 'D485',
    S0037: 'D487',
    S0038: 'D480',
    S0039: 'D479',
    S0040: 'D488',
    S0041: 'D249',
    S0042: 'D065',
    S0043: 'D157',
    S0044: 'D104',
    S0045: 'D174',
    S0046: 'D122',
    S0047: 'D046',
    S0048: 'D173',
    S0049: 'D145',
    S0050: 'D225',
    S0051: 'D062',
    S0052: 'D706',
    S0053: 'D707',
    S0055: 'D709',
    S0056: 'D710',
    S0057: 'D123',
    S0058: 'D711',
    S0059: 'D087',
    S0060: 'D095',
    S0061: 'D266',
    S0062: 'D148',
    S0063: 'D232',
    S0064: 'D128',
    S0065: 'D131',
    S0066: 'D263',
    S0067: 'D246',
    S0068: 'D044',
    S0069: 'D712',
    S0070: 'D713',
    S0071: 'D024',
    S0072: 'D139',
    S0073: 'D714',
    S0074: 'D256',
    S0075: 'D193',
    S0076: 'D155',
    S0077: 'D191',
    S0079: 'D211',
    S0080: 'D134',
    S0081: 'D243',
    S0082: 'D715',
    S0083: 'D716',
    S0084: 'D717',
    S0085: 'D108',
    S0086: 'D718',
    S0087: 'D719',
    S0088: 'D720',
    S0089: 'D038',
    S0090: 'D239',
    S0091: 'D015',
    S0092: 'D721',
    S0093: 'D722',
    S0094: 'D043',
    S0095: 'D197',
    S0096: 'D106',
    S0097: 'D168',
    S0098: 'D141',
    S0099: 'D247',
    S0100: 'D222',
    S0101: 'D160',
    S0102: 'D066',
    S0103: 'D135',
    S0104: 'D084',
    S0105: 'D039',
    S0106: 'D205',
    S0107: 'D032',
    S0108: 'D114',
    S0109: 'D208',
    S0110: 'D204',
    S0111: 'D085',
    S0112: 'D069',
    S0113: 'D223',
    S0114: 'D251',
    S0115: 'D201',
    S0116: 'D060',
    S0117: 'D016',
    S0118: 'D288',
    S0119: 'D724',
    S0120: 'D292',
    S0121: 'D278',
    S0122: 'D289',
    S0123: 'D286',
    S0124: 'D725',
    S0125: 'D064',
    S0126: 'D283',
    S0127: 'D285',
    S0128: 'D284',
    S0129: 'D287',
    S0130: 'D290',
    S0131: 'D293',
    S0132: 'D276',
    S0133: 'D282',
    S0134: 'D727',
    S0135: 'D279',
    S0136: 'D280',
    S0137: 'D031',
    S0138: 'D248',
    S0139: 'D264',
    S0140: 'D169',
    S0141: 'D078',
    S0142: 'D159',
    S0143: 'D224',
    S0144: 'D202',
    S0145: 'D190',
    S0146: 'D729',
    S0147: 'D233',
    S0148: 'D172',
    S0149: 'D730',
    S0150: 'D731',
    S0151: 'D176',
    S0152: 'D013',
    S0153: 'D258',
    S0154: 'D214',
    S0155: 'D005',
    S0156: 'D231',
    S0157: 'D091',
    S0158: 'D259',
    S0159: 'D250',
    S0160: 'D080',
    S0161: 'D140',
    S0162: 'D035',
    S0163: 'D045',
    S0164: 'D179',
    S0165: 'D125',
    S0166: 'D086',
    S0167: 'D132',
    S0168: 'D732',
    S0169: 'D271',
    S0170: 'D274',
    S0171: 'D269',
    S0172: 'D273',
    S0173: 'D272',
    S0174: 'D270',
    S0175: 'D267',
    S0176: 'D268',
    S0177: 'D275',
    S0178: 'D733',
    S0179: 'D166',
    S0180: 'D058',
    S0181: 'D116',
    S0182: 'D236',
    S0183: 'D027',
    S0184: 'D008',
    S0185: 'D238',
    S0186: 'D006',
    S0187: 'D018',
    S0188: 'D144',
    S0189: 'D150',
    S0190: 'D252',
    S0191: 'D183',
    S0192: 'D048',
    S0193: 'D101',
    S0194: 'D089',
    S0195: 'D217',
    S0196: 'D117',
    S0197: 'D014',
    S0198: 'D051',
    S0199: 'D171',
    S0200: 'D196',
    S0201: 'D130',
    S0202: 'D092',
    S0203: 'D023',
    S0204: 'D736',
    S0205: 'D737',
    S0206: 'D076',
    S0207: 'D111',
    S0208: 'D162',
    S0209: 'D182',
    S0210: 'D026',
    S0211: 'D097',
    S0212: 'D170',
    S0213: 'D234',
    S0214: 'D221',
    S0215: 'D012',
    S0216: 'D158',
    S0217: 'D230',
    S0218: 'D199',
    S0219: 'D040',
    S0220: 'D184',
    S0221: 'D178',
    S0222: 'D198',
    S0223: 'D143',
    S0224: 'D042',
    S0225: 'D093',
    S0226: 'D070',
    S0227: 'D079',
    S0228: 'D195',
    S0229: 'D112',
    S0230: 'D088',
    S0231: 'D099',
    S0232: 'D100',
    S0233: 'D071',
    S0234: 'D210',
    S0235: 'D200',
    S0236: 'D127',
    S0237: 'D187',
    S0238: 'D186',
    S0239: 'D096',
    S0240: 'D094',
    S0241: 'D265',
    S0242: 'D021',
    S0243: 'D216',
    S0244: 'D137',
    S0245: 'D074',
    S0246: 'D109',
    S0247: 'D220',
    S0248: 'D075',
    S0249: 'D228',
    S0250: 'D181',
    S0251: 'D120',
    S0252: 'D133',
    S0253: 'D253',
    S0254: 'D072',
    S0255: 'D254',
    S0256: 'D115',
    S0257: 'D245',
    S0258: 'D110',
    S0259: 'D229',
    S0260: 'D041',
    S0261: 'D121',
    S0262: 'D034',
    S0263: 'D257',
    S0264: 'D007',
    S0265: 'D212',
    S0266: 'D165',
    S0267: 'D105',
    S0268: 'D129',
    S0269: 'D047',
    S0270: 'D237',
    S0271: 'D218',
    S0272: 'D020',
    S0273: 'D098',
    S0274: 'D103',
    S0275: 'D147',
    S0276: 'D215',
    S0277: 'D226',
    S0278: 'D010',
    S0279: 'D227',
    S0280: 'D057',
    S0281: 'D056',
    S0282: 'D050',
    S0283: 'D049',
    S0284: 'D059',
    S0285: 'D206',
    S0286: 'D003',
    S0287: 'D030',
    S0288: 'D192',
    S0289: 'D156',
    S0290: 'D113',
    S0291: 'D164',
    S0292: 'D029',
    S0293: 'D209',
    S0294: 'D203',
    S0295: 'D061',
    S0296: 'D124',
    S0297: 'D011',
    S0298: 'D194',
    S0299: 'D213',
    S0300: 'D142',
    S0301: 'D262',
    S0302: 'D219',
    S0303: 'D136',
    S0304: 'D055',
    S0305: 'D149',
    S0306: 'D167',
    S0307: 'D207',
    S0308: 'D241',
    S0309: 'D022',
    S0310: 'D037',
    S0311: 'D119',
    S0312: 'D153',
    S0313: 'D082',
    S0314: 'D002',
    S0315: 'D053',
    S0316: 'D067',
    S0317: 'D189',
    S0318: 'D327',
    S0319: 'D320',
    S0320: 'D312',
    S0321: 'D328',
    S0322: 'D326',
    S0323: 'D317',
    S0324: 'D318',
    S0325: 'D322',
    S0326: 'D319',
    S0327: 'D321',
    S0328: 'D329',
    S0329: 'D325',
    S0330: 'D324',
    S0331: 'D491',
    S0332: 'D492',
    S0333: 'D493',
    S0334: 'D494',
    S0335: 'D495',
    S0336: 'D496',
    S0337: 'D311',
    S0338: 'D301',
    S0339: 'D298',
    S0340: 'D300',
    S0341: 'D316',
    S0342: 'D314',
    S0343: 'D305',
    S0344: 'D303',
    S0345: 'D315',
    S0346: 'D313',
    S0347: 'D309',
    S0348: 'D307',
    S0349: 'D308',
    S0350: 'D299',
    S0351: 'D306',
    S0352: 'D295',
    S0353: 'D296',
    S0354: 'D297',
    S0355: 'D310',
    S0356: 'D302',
    S0357: 'D304',
    S0358: 'D497',
    S0359: 'D498',
    S0360: 'D499',
    S0361: 'D500',
    S0362: 'D501',
    S0363: 'D502',
    S0364: 'D503',
    S0365: 'D504',
    S0366: 'D505',
    S0367: 'D506',
    S0368: 'D507',
    S0369: 'D508',
    S0370: 'D509',
    S0371: 'D510',
    S0372: 'D511',
    S0373: 'D512',
    S0374: 'D513',
    S0375: 'D514',
    S0376: 'D515',
    S0377: 'D516',
    S0378: 'D517',
    S0379: 'D518',
    S0380: 'D519',
    S0381: 'D520',
    S0382: 'D521',
    S0383: 'D522',
    S0384: 'D523',
    S0385: 'D524',
    S0386: 'D525',
    S0387: 'D526',
    S0388: 'D527',
    S0389: 'D528',
    S0390: 'D529',
    S0391: 'D530',
    S0392: 'D531',
    S0393: 'D532',
    S0394: 'D533',
    S0395: 'D534',
    S0396: 'D535',
    S0397: 'D536',
    S0398: 'D537',
    S0399: 'D538',
    S0400: 'D540',
    S0401: 'D541',
    S0402: 'D542',
    S0403: 'D543',
    S0404: 'D544',
    S0405: 'D545',
    S0406: 'D546',
    S0407: 'D547',
    S0408: 'D548',
    S0409: 'D549',
    S0410: 'D550',
    S0411: 'D551',
    S0412: 'D552',
    S0413: 'D553',
    S0414: 'D554',
    S0415: 'D555',
    S0416: 'D556',
    S0417: 'D558',
    S0418: 'D559',
    S0419: 'D560',
    S0420: 'D561',
    S0421: 'D562',
    S0422: 'D563',
    S0423: 'D294',
    S0424: 'D476',
    S0425: 'D664',
    S0426: 'D524',
    S0428: 'D573',
    S0429: 'D574',
    S0430: 'D575',
    S0431: 'D737',
    S0432: 'D577',
    S0433: 'D663',
    S0434: 'D579',
    S0435: 'D583',
    S0436: 'D584',
    S0437: 'D585',
    S0438: 'D586',
    S0439: 'D587',
    S0440: 'D588',
    S0441: 'D589',
    S0442: 'D590',
    S0443: 'D591',
    S0444: 'D539',
    S0445: 'D592',
    S0446: 'D557',
    S0447: 'D364',
    S0448: 'D462',
    S0449: 'D593',
    S0450: 'D594',
    S0451: 'D599',
    S0452: 'D600',
    S0453: 'D601',
    S0454: 'D196',
    S0455: 'D603',
    S0456: 'D604',
    S0457: 'D605',
    S0458: 'D606',
    S0459: 'D595',
    S0460: 'D596',
    S0461: 'D690',
    S0462: 'D691',
    S0463: 'D692',
    S0464: 'D693',
    S0465: 'D694',
    S0466: 'D695',
    S0467: 'D696',
    S0468: 'D697',
    S0469: 'D698',
    S0470: 'D699',
    S0471: 'D700',
    S0472: 'D701',
    S0473: 'D702',
    S0474: 'D667',
    S0475: 'D668',
    S0476: 'D669',
    S0477: 'D670',
    S0478: 'D671',
    S0479: 'D672',
    S0480: 'D673',
    S0481: 'D674',
    S0482: 'D675',
    S0483: 'D676',
    S0484: 'D677',
    S0485: 'D678',
    S0486: 'D679',
    S0487: 'D051',
    S0488: 'D092',
    S0494: 'D607',
    S0495: 'D608',
    S0496: 'D609',
    S0497: 'D610',
    S0498: 'D611',
    S0499: 'D612',
    S0500: 'D613',
    S0501: 'D076',
    S0502: 'D615',
    S0503: 'D616',
    S0504: 'D617',
    S0505: 'D618',
    S0506: 'D619',
    S0507: 'D620',
    S0508: 'D621',
    S0509: 'D622',
    S0510: 'D623',
    S0511: 'D624',
    S0512: 'D625',
    S0513: 'D734',
    S0514: 'D627',
    S0515: 'D628',
    S0516: 'D629',
    S0517: 'D630',
    S0518: 'D626',
    S0519: 'D632',
    S0520: 'D633',
    S0521: 'D634',
    S0522: 'D635',
    S0523: 'D636',
    S0524: 'D637',
    S0525: 'D638',
    S0526: 'D639',
    S0527: 'D640',
    S0528: 'D641',
    S0529: 'D642',
    S0530: 'D643',
    S0531: 'D602',
    S0532: 'D645',
    S0533: 'D646',
    S0534: 'D647',
    S0535: 'D648',
    S0536: 'D649',
    S0537: 'D650',
    S0538: 'D651',
    S0539: 'D652',
    S0540: 'P019',
    S0541: 'D654',
    S0542: 'D655',
    S0543: 'D734',
    S0544: 'D170',
    S0545: 'D658',
    S0546: 'D659',
    S0547: 'D660',
    S0548: 'D661',
    S0549: 'D662',
    S0550: 'D750',
    S0552: 'D665',
    S0553: 'D404',
    S0554: 'D738',
    S0555: 'D742',
    S0556: 'D128',
    S0558: 'P020',
    S0559: 'D255',
    S0560: 'D255',
    S0561: 'P058',
    S0562: 'D090',
    S0563: 'D640',
    S0564: 'D471',
    S0565: 'D482',
    S0566: 'D058',
    S0567: 'D746',
    S0568: 'D747',
    S0569: 'D748',
    S0570: 'D749',
    S0573: 'D239',
    S0574: 'D138',
    S0575: 'D001',
    S0576: 'D689',
    S0577: 'D597',
    S0578: 'D598',
    S0580: 'D277',
    S0581: 'D161',
    S0582: 'D279',
    S0585: 'D074',
    S0586: 'D248',
    S0588: 'P072',
    S0589: 'D151',
    S0590: 'D744',
    S0598: 'D102',
    S0599: 'D280',
    S0600: 'D703',
    S0601: 'D704',
    S0602: 'P005',
    S0603: 'D565',
    S0604: 'D566',
    S0605: 'D567',
    S0606: 'D568',
    S0607: 'D569',
    S0608: 'D570',
    S0609: 'D571',
    S0610: 'D572',
    S0611: 'D580',
    S0612: 'D581',
    S0613: 'D582',
    S0614: 'D570',
    S0615: 'P071',
    S0616: 'P071',
    S0617: 'D197',
    S0618: 'D330',
    S0619: 'D331',
    S0620: 'D332',
    S0621: 'D333',
    S0622: 'D334',
    S0623: 'D335',
    S0625: 'D337',
    S0626: 'D338',
    S0627: 'D339',
    S0628: 'D340',
    S0629: 'D341',
    S0630: 'D342',
    S0631: 'D343',
    S0632: 'D344',
    S0633: 'D345',
    S0634: 'D350',
    S0635: 'D351',
    S0636: 'D352',
    S0637: 'D353',
    S0638: 'D354',
    S0639: 'D355',
    S0640: 'D356',
    S0641: 'D357',
    S0642: 'D358',
    S0643: 'D359',
    S0644: 'D360',
    S0645: 'D361',
    S0646: 'D362',
    S0647: 'D363',
    S0648: 'D365',
    S0649: 'D366',
    S0650: 'D367',
    S0651: 'D368',
    S0652: 'D369',
    S0653: 'D370',
    S0654: 'D371',
    S0655: 'D372',
    S0656: 'D373',
    S0657: 'D374',
    S0658: 'D375',
    S0659: 'D376',
    S0660: 'D377',
    S0661: 'D378',
    S0662: 'D379',
    S0663: 'D380',
    S0664: 'D381',
    S0665: 'D382',
    S0666: 'D383',
    S0667: 'D384',
    S0668: 'D385',
    S0669: 'D386',
    S0670: 'D387',
    S0671: 'D388',
    S0672: 'D389',
    S0674: 'D391',
    S0675: 'D392',
    S0676: 'D393',
    S0677: 'D394',
    S0678: 'D395',
    S0679: 'D396',
    S0680: 'D397',
    S0681: 'D398',
    S0682: 'D399',
    S0683: 'D400',
    S0684: 'D401',
    S0685: 'D402',
    S0686: 'D403',
    S0687: 'D405',
    S0688: 'D406',
    S0689: 'D407',
    S0690: 'D408',
    S0691: 'D409',
    S0692: 'D410',
    S0693: 'D411',
    S0694: 'D412',
    S0696: 'D414',
    S0697: 'D415',
    S0698: 'D416',
    S0700: 'D418',
    S0701: 'D419',
    S0702: 'D420',
    S0703: 'D421',
    S0704: 'D422',
    S0705: 'D423',
    S0706: 'D424',
    S0707: 'D425',
    S0708: 'D426',
    S0709: 'D427',
    S0710: 'D428',
    S0711: 'D429',
    S0712: 'D430',
    S0713: 'D431',
    S0714: 'D432',
    S0715: 'D433',
    S0716: 'D434',
    S0717: 'D435',
    S0718: 'D436',
    S0719: 'D437',
    S0720: 'D438',
    S0721: 'D439',
    S0722: 'D440',
    S0723: 'D441',
    S0724: 'D442',
    S0725: 'D443',
    S0726: 'D444',
    S0727: 'D445',
    S0728: 'D446',
    S0729: 'D447',
    S0730: 'D448',
    S0731: 'D449',
    S0732: 'D450',
    S0733: 'D451',
    S0734: 'D452',
    S0735: 'D453',
    S0736: 'D454',
    S0737: 'D455',
    S0738: 'D456',
    S0739: 'D457',
    S0740: 'D458',
    S0741: 'D459',
    S0742: 'D460',
    S0743: 'D461',
    S0744: 'D463',
    S0745: 'D464',
    S0746: 'D465',
    S0747: 'D466',
    S0748: 'D467',
    S0749: 'D468',
    S0750: 'D469',
    S0751: 'D576',
    S0752: 'D371',
    S0753: 'D378',
    S0754: 'D369',
    S0755: 'D366',
    S0756: 'D412',
    S0757: 'D400',
    S0758: 'D381',
    S0759: 'D385',
    S0760: 'D376',
    S0761: 'D412',
    S0762: 'D644',
    S0763: 'D723',
    S0764: 'D728',
    S0765: 'D656',
    S0766: 'D564',
    S0767: 'D564',
    S0768: 'P026',
    S0769: 'P003',
    S0770: 'P007',
    S0771: 'P008',
    S0772: 'P013',
    S0773: 'P014',
    S0774: 'P015',
    S0775: 'P020',
    S0776: 'P022',
    S0777: 'P027',
    S0778: 'P050',
    S0779: 'P061',
    S0780: 'D562',
    S0781: 'P070',
    S0782: 'P081',
    S0783: 'P089',
    S0784: 'P091',
    S0785: 'P023',
    S0786: 'P028',
    S0787: 'P032',
    S0788: 'P042',
    S0789: 'P043',
    S0790: 'P051',
    S0791: 'P050',
    S0792: 'P055',
    S0793: 'P057',
    S0794: 'P067',
    S0795: 'P071',
    S0796: 'P083',
    S0797: 'P067',
    S0798: 'P086',
    S0799: 'P005',
    S0800: 'P006',
    S0801: 'P012',
    S0803: 'P012',
    S0804: 'P016',
    S0805: 'P016',
    S0806: 'P016',
    S0807: 'P016',
    S0808: 'P017',
    S0809: 'P077',
    S0810: 'D563',
    S0811: 'P031',
    S0812: 'P031',
    S0813: 'P033',
    S0814: 'P033',
    S0817: 'P052',
    S0818: 'P039',
    S0819: 'P069',
    S0820: 'P059',
    S0821: 'P069',
    S0822: 'P069',
    S0824: 'P077',
    S0825: 'P077',
    S0826: 'P078',
    S0827: 'P078',
    S0829: 'P078',
    S0830: 'P078',
    S0831: 'P078',
    S0832: 'P078',
    S0834: 'P093',
    S0835: 'P041',
    S0836: 'P002',
    S0837: 'P004',
    S0838: 'P009',
    S0839: 'P011',
    S0840: 'P005',
    S0841: 'P011',
    S0842: 'P087',
    S0843: 'P018',
    S0844: 'D021',
    S0845: 'P021',
    S0846: 'P024',
    S0847: 'P025',
    S0848: 'P052',
    S0849: 'P030',
    S0850: 'P038',
    S0851: 'P044',
    S0852: 'P046',
    S0853: 'P047',
    S0857: 'P048',
    S0858: 'P049',
    S0860: 'P053',
    S0861: 'P054',
    S0862: 'P056',
    S0863: 'P056',
    S0864: 'P056',
    S0865: 'P060',
    S0866: 'P062',
    S0867: 'P065',
    S0868: 'P066',
    S0869: 'P068',
    S0870: 'D216',
    S0871: 'D109',
    S0873: 'P073',
    S0874: 'P074',
    S0875: 'P074',
    S0876: 'P093',
    S0877: 'P075',
    S0878: 'P076',
    S0879: 'P079',
    S0880: 'P079',
    S0881: 'D216',
    S0883: 'P080',
    S0885: 'P082',
    S0886: 'P082',
    S0887: 'P085',
    S0889: 'P092',
    S0890: 'P094',
    S0891: 'D751',
    S0892: 'D752',
    S0893: 'D489',
    S0894: 'P094',
    S0895: 'D755',
    S0896: 'D478',
    S0897: 'D126',
    S0898: 'D146',
    S0899: 'D163',
    S0900: 'D185',
    S0901: 'D486',
    S0902: 'D756',
    S0903: 'D757',
    S0904: 'D152',
    S0905: 'D686',
    S0906: 'P095',
    S0907: 'P058',
    S0908: 'P034',
    S0909: 'D014',
    S0910: 'P077',
    S0911: 'P036',
    S0912: 'D130',
    S0917: 'D019',
    S0918: 'D031',
    S0920: 'D622',
    S0921: 'D021',
    S0923: 'P091',
    S0924: 'D074',
    S0925: 'P039',
    S0926: 'P062',
    S0927: 'D027',
    S0928: 'P032',
    S0930: 'P043',
    S0931: 'P001',
    S0932: 'P001',
    S0933: 'P001',
    S0934: 'P096',
    S0935: 'D708',
    S0936: 'D687',
    S0937: 'D688',
    S0938: 'P035',
    S0939: 'P040',
    S0940: 'D758',
};
