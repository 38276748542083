/**
 * An object mapping from province id to its metadata information
 * (Generated from mapping csv file using a script. See /scripts folder README.)
 */
export const PROVINCES = {
    P001: {
        provinceId: 'P001',
        provinceName: '[Holy See]',
        provinceNameAlt: 'Exempt Dioceses',
        shapeFileId: 'S0931',
        shapeFileId2: 'S0932',
        shapeFileId3: 'S0933',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'mapped approximately, based on all dioceses not incorporated into a province during any given timeslice',
    },
    P002: {
        provinceId: 'P002',
        provinceName: 'Acerenza',
        provinceNameAlt: '',
        shapeFileId: 'S0836',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P003: {
        provinceId: 'P003',
        provinceName: 'Aix',
        provinceNameAlt: '',
        shapeFileId: 'S0769',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P004: {
        provinceId: 'P004',
        provinceName: 'Amalfi',
        provinceNameAlt: '',
        shapeFileId: 'S0837',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P005: {
        provinceId: 'P005',
        provinceName: 'Aquileia',
        provinceNameAlt: '',
        shapeFileId: 'S0799',
        shapeFileId2: 'S0840',
        shapeFileId3: 'S0602',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P006: {
        provinceId: 'P006',
        provinceName: 'Arles',
        provinceNameAlt: '',
        shapeFileId: 'S0800',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P007: {
        provinceId: 'P007',
        provinceName: 'Armagh',
        provinceNameAlt: '',
        shapeFileId: 'S0770',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P008: {
        provinceId: 'P008',
        provinceName: 'Auch',
        provinceNameAlt: '',
        shapeFileId: 'S0771',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P009: {
        provinceId: 'P009',
        provinceName: 'Avignon',
        provinceNameAlt: '',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1475',
        yearSuppressed: '',
        notes: 'does not appear on maps',
    },
    P011: {
        provinceId: 'P011',
        provinceName: 'Bari',
        provinceNameAlt: '',
        shapeFileId: 'S0839',
        shapeFileId2: 'S0841',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P012: {
        provinceId: 'P012',
        provinceName: 'Benevento',
        provinceNameAlt: '',
        shapeFileId: 'S0801',
        shapeFileId2: 'S0803',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P013: {
        provinceId: 'P013',
        provinceName: 'Besançon',
        provinceNameAlt: '',
        shapeFileId: 'S0772',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P014: {
        provinceId: 'P014',
        provinceName: 'Bordeaux',
        provinceNameAlt: '',
        shapeFileId: 'S0773',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P015: {
        provinceId: 'P015',
        provinceName: 'Bourges',
        provinceNameAlt: '',
        shapeFileId: 'S0774',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P016: {
        provinceId: 'P016',
        provinceName: 'Braga',
        provinceNameAlt: '',
        shapeFileId: 'S0804',
        shapeFileId2: 'S0806',
        shapeFileId3: 'S0807',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P017: {
        provinceId: 'P017',
        provinceName: 'Bremen',
        provinceNameAlt: '',
        shapeFileId: 'S0808',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P018: {
        provinceId: 'P018',
        provinceName: 'Brindisi',
        provinceNameAlt: '',
        shapeFileId: 'S0843',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: 'Unrecognized as an archdiocese until the 16th century',
    },
    P019: {
        provinceId: 'P019',
        provinceName: 'Cagliari',
        provinceNameAlt: '',
        shapeFileId: 'S0540',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P020: {
        provinceId: 'P020',
        provinceName: 'Canterbury',
        provinceNameAlt: '',
        shapeFileId: 'S0775',
        shapeFileId2: 'S0558',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P021: {
        provinceId: 'P021',
        provinceName: 'Capua',
        provinceNameAlt: '',
        shapeFileId: 'S0845',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P022: {
        provinceId: 'P022',
        provinceName: 'Cashel',
        provinceNameAlt: 'Lismore',
        shapeFileId: 'S0776',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P023: {
        provinceId: 'P023',
        provinceName: 'Köln',
        provinceNameAlt: 'Cologne',
        shapeFileId: 'S0785',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P024: {
        provinceId: 'P024',
        provinceName: 'Conza',
        provinceNameAlt: '',
        shapeFileId: 'S0846',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P025: {
        provinceId: 'P025',
        provinceName: 'Cosenza',
        provinceNameAlt: '',
        shapeFileId: 'S0847',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1090',
        yearSuppressed: '',
        notes: '',
    },
    P026: {
        provinceId: 'P026',
        provinceName: 'Dublin',
        provinceNameAlt: '',
        shapeFileId: 'S0768',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P027: {
        provinceId: 'P027',
        provinceName: 'Embrun',
        provinceNameAlt: '',
        shapeFileId: 'S0777',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P028: {
        provinceId: 'P028',
        provinceName: 'Esztergom',
        provinceNameAlt: '',
        shapeFileId: 'S0786',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P030: {
        provinceId: 'P030',
        provinceName: 'Firenze',
        provinceNameAlt: 'Florence',
        shapeFileId: 'S0849',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1420',
        yearSuppressed: '',
        notes: '',
    },
    P031: {
        provinceId: 'P031',
        provinceName: 'Genova',
        provinceNameAlt: 'Genoa',
        shapeFileId: 'S0811',
        shapeFileId2: 'S0812',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P032: {
        provinceId: 'P032',
        provinceName: 'Gniezno',
        provinceNameAlt: '',
        shapeFileId: 'S0787',
        shapeFileId2: 'S0928',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'Vilnius (1409-1501), Lebus (1124-1501), Wroclaw (Breslau) and Gniezno (1000-1501), Poznan and Plock (1075-1501), Samogitia(1440-1501), Chełm (1375-1501)',
    },
    P033: {
        provinceId: 'P033',
        provinceName: 'Grado',
        provinceNameAlt: 'Venezia',
        shapeFileId: 'S0813',
        shapeFileId2: 'S0814',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'In 1451, Grado is suppressed and the seat of the archbishopric is transferred to Venezia',
    },
    P034: {
        provinceId: 'P034',
        provinceName: 'Granada',
        provinceNameAlt: '',
        shapeFileId: 'S0908',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1492',
        yearSuppressed: '',
        notes: 'does not appear on maps',
    },
    P035: {
        provinceId: 'P035',
        provinceName: 'Halyč ',
        provinceNameAlt: '',
        shapeFileId: 'S0938',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1375',
        yearSuppressed: '1414',
        notes: 'missing Kiev, Wlodzimierz, Zytomierz (unmapped) ',
    },
    P036: {
        provinceId: 'P036',
        provinceName: 'Kalocsa',
        provinceNameAlt: '',
        shapeFileId: 'S0911',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P038: {
        provinceId: 'P038',
        provinceName: 'Lisboa',
        provinceNameAlt: 'Lisbon',
        shapeFileId: 'S0850',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1393',
        yearSuppressed: '',
        notes: '',
    },
    P039: {
        provinceId: 'P039',
        provinceName: 'Lund',
        provinceNameAlt: '',
        shapeFileId: 'S0818',
        shapeFileId2: 'S0925',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1124',
        yearSuppressed: '',
        notes: '',
    },
    P040: {
        provinceId: 'P040',
        provinceName: 'Lviv',
        provinceNameAlt: '',
        shapeFileId: 'S0939',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'missing territory from unmapped dioceses of Kiev, Wlodzimierz, Zytomierz',
    },
    P041: {
        provinceId: 'P041',
        provinceName: 'Lyon',
        provinceNameAlt: '',
        shapeFileId: 'S0835',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P042: {
        provinceId: 'P042',
        provinceName: 'Magdeburg',
        provinceNameAlt: '',
        shapeFileId: 'S0788',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P043: {
        provinceId: 'P043',
        provinceName: 'Mainz',
        provinceNameAlt: '',
        shapeFileId: 'S0789',
        shapeFileId2: 'S0930',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Paderborn (999-1429); (1444-1501)',
    },
    P044: {
        provinceId: 'P044',
        provinceName: 'Manfredonia',
        provinceNameAlt: '',
        shapeFileId: 'S0851',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1258',
        yearSuppressed: '',
        notes: '',
    },
    P046: {
        provinceId: 'P046',
        provinceName: 'Messina',
        provinceNameAlt: '',
        shapeFileId: 'S0852',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P047: {
        provinceId: 'P047',
        provinceName: 'Milano',
        provinceNameAlt: 'Milan',
        shapeFileId: 'S0853',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P048: {
        provinceId: 'P048',
        provinceName: 'Monreale',
        provinceNameAlt: '',
        shapeFileId: 'S0857',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1086',
        yearSuppressed: '',
        notes: '',
    },
    P049: {
        provinceId: 'P049',
        provinceName: 'Napoli',
        provinceNameAlt: 'Naples',
        shapeFileId: 'S0858',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P050: {
        provinceId: 'P050',
        provinceName: 'Narbonne',
        provinceNameAlt: '',
        shapeFileId: 'S0778',
        shapeFileId2: 'S0791',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P051: {
        provinceId: 'P051',
        provinceName: 'Nicosia',
        provinceNameAlt: '',
        shapeFileId: 'S0790',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1196',
        yearSuppressed: '',
        notes: 'All Dioceses (1196-1571)',
    },
    P052: {
        provinceId: 'P052',
        provinceName: 'Nidaros',
        provinceNameAlt: 'Trondheim',
        shapeFileId: 'S0817',
        shapeFileId2: 'S0848',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1152',
        yearSuppressed: '',
        notes: '',
    },
    P053: {
        provinceId: 'P053',
        provinceName: 'Oristano',
        provinceNameAlt: '',
        shapeFileId: 'S0860',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P054: {
        provinceId: 'P054',
        provinceName: 'Otranto',
        provinceNameAlt: '',
        shapeFileId: 'S0861',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Santa Giusta (1093-1503)',
    },
    P055: {
        provinceId: 'P055',
        provinceName: 'Palermo',
        provinceNameAlt: '',
        shapeFileId: 'S0792',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Agrigento (1088-1501); Mazara (1093-1501)',
    },
    P056: {
        provinceId: 'P056',
        provinceName: 'Pisa',
        provinceNameAlt: '',
        shapeFileId: 'S0862',
        shapeFileId2: 'S0863',
        shapeFileId3: 'S0864',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P057: {
        provinceId: 'P057',
        provinceName: 'Prague',
        provinceNameAlt: '',
        shapeFileId: 'S0793',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1344',
        yearSuppressed: '',
        notes: '',
    },
    P058: {
        provinceId: 'P058',
        provinceName: 'Ragusa',
        provinceNameAlt: 'Dubrovnik',
        shapeFileId: 'S0907',
        shapeFileId2: 'S0561',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P059: {
        provinceId: 'P059',
        provinceName: 'Ravenna',
        provinceNameAlt: '',
        shapeFileId: 'S0820',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P060: {
        provinceId: 'P060',
        provinceName: 'Reggio-Calabria',
        provinceNameAlt: '',
        shapeFileId: 'S0865',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P061: {
        provinceId: 'P061',
        provinceName: 'Reims',
        provinceNameAlt: '',
        shapeFileId: 'S0779',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Noyon (1146-1501) and Arras (1093-1501)',
    },
    P062: {
        provinceId: 'P062',
        provinceName: 'Riga',
        provinceNameAlt: '',
        shapeFileId: 'S0866',
        shapeFileId2: 'S0926',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1255',
        yearSuppressed: '',
        notes: '',
    },
    P063: {
        provinceId: 'P063',
        provinceName: 'Roma',
        provinceNameAlt: 'Rome',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            "Not mapped, since it doesn't function as an ordinary province [see Exempt Dioceses]",
    },
    P065: {
        provinceId: 'P065',
        provinceName: 'Rouen',
        provinceNameAlt: '',
        shapeFileId: 'S0867',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P066: {
        provinceId: 'P066',
        provinceName: 'Salerno',
        provinceNameAlt: '',
        shapeFileId: 'S0868',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P067: {
        provinceId: 'P067',
        provinceName: 'Salzburg',
        provinceNameAlt: '',
        shapeFileId: 'S0794',
        shapeFileId2: 'S0797',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P068: {
        provinceId: 'P068',
        provinceName: 'Santa Severina',
        provinceNameAlt: '',
        shapeFileId: 'S0869',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P069: {
        provinceId: 'P069',
        provinceName: 'Santiago',
        provinceNameAlt: '',
        shapeFileId: 'S0821',
        shapeFileId2: 'S0819',
        shapeFileId3: 'S0822',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P070: {
        provinceId: 'P070',
        provinceName: 'Sens',
        provinceNameAlt: '',
        shapeFileId: 'S0781',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P071: {
        provinceId: 'P071',
        provinceName: 'Sevilla',
        provinceNameAlt: 'Seville',
        shapeFileId: 'S0795',
        shapeFileId2: 'S0615',
        shapeFileId3: 'S0616',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P072: {
        provinceId: 'P072',
        provinceName: 'Siena',
        provinceNameAlt: '',
        shapeFileId: 'S0588',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1459',
        yearSuppressed: '',
        notes: '',
    },
    P073: {
        provinceId: 'P073',
        provinceName: 'Sorrento',
        provinceNameAlt: '',
        shapeFileId: 'S0873',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P074: {
        provinceId: 'P074',
        provinceName: 'Split',
        provinceNameAlt: '',
        shapeFileId: 'S0874',
        shapeFileId2: 'S0875',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P075: {
        provinceId: 'P075',
        provinceName: 'Taranto',
        provinceNameAlt: '',
        shapeFileId: 'S0877',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P076: {
        provinceId: 'P076',
        provinceName: 'Tarentaise',
        provinceNameAlt: '',
        shapeFileId: 'S0878',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P077: {
        provinceId: 'P077',
        provinceName: 'Tarragona',
        provinceNameAlt: '',
        shapeFileId: 'S0825',
        shapeFileId2: 'S0910',
        shapeFileId3: 'S0824',
        shapeFileId4: 'S0809',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P078: {
        provinceId: 'P078',
        provinceName: 'Toledo',
        provinceNameAlt: '',
        shapeFileId: 'S0829',
        shapeFileId2: 'S0830',
        shapeFileId3: 'S0831',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P079: {
        provinceId: 'P079',
        provinceName: 'Torres',
        provinceNameAlt: '',
        shapeFileId: 'S0879',
        shapeFileId2: 'S0880',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P080: {
        provinceId: 'P080',
        provinceName: 'Toulouse',
        provinceNameAlt: '',
        shapeFileId: 'S0883',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P081: {
        provinceId: 'P081',
        provinceName: 'Tours',
        provinceNameAlt: '',
        shapeFileId: 'S0782',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P082: {
        provinceId: 'P082',
        provinceName: 'Trani',
        provinceNameAlt: '',
        shapeFileId: 'S0885',
        shapeFileId2: 'S0886',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P083: {
        provinceId: 'P083',
        provinceName: 'Trier',
        provinceNameAlt: '',
        shapeFileId: 'S0796',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P085: {
        provinceId: 'P085',
        provinceName: 'Tuam',
        provinceNameAlt: '',
        shapeFileId: 'S0887',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P086: {
        provinceId: 'P086',
        provinceName: 'Uppsala',
        provinceNameAlt: '',
        shapeFileId: 'S0798',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'Uppsala (1123-1588), Vasteras (1100-1527), Strangnas (1129-1527), Linkoping (1100-1527), Skara (1014-1527), Vaxjo (1170-1527), Abo (1276-1522)',
    },
    P087: {
        provinceId: 'P087',
        provinceName: 'Rossano',
        provinceNameAlt: '',
        shapeFileId: 'S0842',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P089: {
        provinceId: 'P089',
        provinceName: 'Vienne',
        provinceNameAlt: '',
        shapeFileId: 'S0783',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P091: {
        provinceId: 'P091',
        provinceName: 'York',
        provinceNameAlt: '',
        shapeFileId: 'S0784',
        shapeFileId2: 'S0923',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Carlisle (1133-1501)',
    },
    P092: {
        provinceId: 'P092',
        provinceName: 'Zadar',
        provinceNameAlt: 'Zara',
        shapeFileId: 'S0889',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P093: {
        provinceId: 'P093',
        provinceName: 'Zaragoza',
        provinceNameAlt: '',
        shapeFileId: 'S0834',
        shapeFileId2: 'S0876',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1318',
        yearSuppressed: '',
        notes: '',
    },
    P094: {
        provinceId: 'P094',
        provinceName: 'Scotland',
        provinceNameAlt: '',
        shapeFileId: 'S0890',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'the “ecclesia Scoticana” did not have a metropolitan until 1472, with the elevation of St Andrew’s, followed by the elevation of Glasgow in 1492',
    },
    P095: {
        provinceId: 'P095',
        provinceName: 'Bar',
        provinceNameAlt: 'Antibari',
        shapeFileId: 'S0906',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    P096: {
        provinceId: 'P096',
        provinceName: 'Candia',
        provinceNameAlt: '',
        shapeFileId: 'S0934',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1213',
        yearSuppressed: '1669',
        notes: '11 suffragan dioceses, currently unmapped (no information)',
    },
};
