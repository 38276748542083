/**
 * An object mapping from diocese id to its metadata information
 * (Generated from mapping csv file using a script. See /scripts folder README.)
 */
export const DIOCESES = {
    D001: {
        dioceseId: 'D001',
        diocsesName: 'Acerenza',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0575',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1082',
        yearSuppressed: '',
        notes: 'United with Matera in 1203, split 1440-1444, reunited 1444',
    },
    D002: {
        dioceseId: 'D002',
        diocsesName: 'Acerno',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0314',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1090',
        yearSuppressed: '',
        notes: '',
    },
    D003: {
        dioceseId: 'D003',
        diocsesName: 'Acerra',
        diocsesNameAlt: '',
        province: 'Napoli',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0286',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1056',
        yearSuppressed: '',
        notes: '',
    },
    D004: {
        dioceseId: 'D004',
        diocsesName: 'Adria',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0011',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Episcopal seat at Rovigo',
    },
    D005: {
        dioceseId: 'D005',
        diocsesName: 'Alatri',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0155',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D006: {
        dioceseId: 'D006',
        diocsesName: 'Alba',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0186',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D007: {
        dioceseId: 'D007',
        diocsesName: 'Albenga',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: 'Genova',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0264',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'formally made a suffragan of Genova in 1159, though it continued to resist this change until at least 1213',
    },
    D008: {
        dioceseId: 'D008',
        diocsesName: 'Alessandria',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0184',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1175',
        yearSuppressed: '',
        notes: 'United with Acqui from 1205-1405',
    },
    D010: {
        dioceseId: 'D010',
        diocsesName: 'Alife',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0278',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D011: {
        dioceseId: 'D011',
        diocsesName: 'Amalfi',
        diocsesNameAlt: '',
        province: 'Amalfi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0297',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D012: {
        dioceseId: 'D012',
        diocsesName: 'Amelia',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0215',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D013: {
        dioceseId: 'D013',
        diocsesName: 'Anagni',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0152',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D014: {
        dioceseId: 'D014',
        diocsesName: 'Ancona',
        diocsesNameAlt: 'Ancona and Umana',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0197',
        shapeFileId2: 'S0909',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'gains territory from suppressed Umana in 1422, renamed Ancona and Umana',
    },
    D015: {
        dioceseId: 'D015',
        diocsesName: 'Andria',
        diocsesNameAlt: '',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0091',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '',
        notes: '',
    },
    D016: {
        dioceseId: 'D016',
        diocsesName: 'Anglona',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0117',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '',
        notes: '',
    },
    D018: {
        dioceseId: 'D018',
        diocsesName: 'Acqui',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0187',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1175',
        yearSuppressed: '',
        notes:
            'lost territory with erection of Alessandria in 1175, but that diocese was then joined to Acqui in union from 1205-1405',
    },
    D019: {
        dioceseId: 'D019',
        diocsesName: 'Aquileia',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0009',
        shapeFileId2: 'S0917',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Episcopal seat at Cividale',
    },
    D020: {
        dioceseId: 'D020',
        diocsesName: 'Aquino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0272',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'suffragan of Capua until 11th century',
    },
    D021: {
        dioceseId: 'D021',
        diocsesName: 'Arezzo',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0242',
        shapeFileId2: 'S0921',
        shapeFileId3: 'S0844',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D022: {
        dioceseId: 'D022',
        diocsesName: 'Ariano',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0309',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D023: {
        dioceseId: 'D023',
        diocsesName: 'Ascoli Piceno',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0203',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D024: {
        dioceseId: 'D024',
        diocsesName: 'Ascoli Satriano',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0071',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D026: {
        dioceseId: 'D026',
        diocsesName: 'Assisi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0210',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D027: {
        dioceseId: 'D027',
        diocsesName: 'Asti',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0183',
        shapeFileId2: 'S0927',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'loses territory for the creation of Mondovì in 1388',
    },
    D029: {
        dioceseId: 'D029',
        diocsesName: 'Avellino',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0597',
        shapeFileId2: 'S0292',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United with Frigento in 1466',
    },
    D030: {
        dioceseId: 'D030',
        diocsesName: 'Aversa',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0287',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1053',
        yearSuppressed: '',
        notes: '',
    },
    D031: {
        dioceseId: 'D031',
        diocsesName: 'Bagnoregio',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0137',
        shapeFileId2: 'S0918',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1501',
        notes: 'United with Viterbo from 10 February - 17 October 1449',
    },
    D032: {
        dioceseId: 'D032',
        diocsesName: 'Belcastro',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0107',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1122',
        yearSuppressed: '',
        notes: '',
    },
    D033: {
        dioceseId: 'D033',
        diocsesName: 'Belluno',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1197',
        notes:
            'United with Feltre in 1197, Loses Feltre in 1462; only represented in union with Feltre in maps',
    },
    D034: {
        dioceseId: 'D034',
        diocsesName: 'Benevento',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0262',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D035: {
        dioceseId: 'D035',
        diocsesName: 'Bergamo',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0162',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D037: {
        dioceseId: 'D037',
        diocsesName: 'Bisaccia',
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0310',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1090',
        yearSuppressed: '',
        notes: '',
    },
    D038: {
        dioceseId: 'D038',
        diocsesName: 'Bisceglie',
        diocsesNameAlt: '',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0089',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D039: {
        dioceseId: 'D039',
        diocsesName: 'Bisignano',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0105',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D040: {
        dioceseId: 'D040',
        diocsesName: 'Bobbio',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0219',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1014',
        yearSuppressed: '',
        notes: '',
    },
    D041: {
        dioceseId: 'D041',
        diocsesName: 'Bojano',
        diocsesNameAlt: 'Boiano',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0260',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'founded sometime before 1071',
    },
    D042: {
        dioceseId: 'D042',
        diocsesName: 'Bologna',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0224',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D043: {
        dioceseId: 'D043',
        diocsesName: 'Bova',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0094',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D044: {
        dioceseId: 'D044',
        diocsesName: 'Bovino',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0068',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D045: {
        dioceseId: 'D045',
        diocsesName: 'Brescia',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0163',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D046: {
        dioceseId: 'D046',
        diocsesName: 'Brindisi',
        diocsesNameAlt: 'Oria',
        province: 'Brindisi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0047',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes:
            'Oria served temporarily as seat of diocese of Brindisi during 11th century; when see was returned to Brindisi at end of the 11th century, Oria was dissolved and made part of diocese of Brindisi; Brindisi claimed to be an archbishopric, but this was not recognized until 1591',
    },
    D047: {
        dioceseId: 'D047',
        diocsesName: 'Brugnato',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0269',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1133',
        yearSuppressed: '',
        notes: '',
    },
    D048: {
        dioceseId: 'D048',
        diocsesName: 'Cagli',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0192',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D049: {
        dioceseId: 'D049',
        diocsesName: 'Cajazzo',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0283',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D050: {
        dioceseId: 'D050',
        diocsesName: 'Calvi',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0282',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D051: {
        dioceseId: 'D051',
        diocsesName: 'Camerino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0198',
        shapeFileId2: 'S0487',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1320',
        yearSuppressed: '',
        notes: '',
    },
    D052: {
        dioceseId: 'D052',
        diocsesName: 'Caorle',
        diocsesNameAlt: '',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0023',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D053: {
        dioceseId: 'D053',
        diocsesName: 'Capaccio',
        diocsesNameAlt: 'Paestum',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0315',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1160',
        yearSuppressed: '',
        notes: '',
    },
    D054: {
        dioceseId: 'D054',
        diocsesName: 'Koper',
        diocsesNameAlt: 'Capodistria',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0001',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: "also known as Capo d'Istria",
    },
    D055: {
        dioceseId: 'D055',
        diocsesName: 'Capri',
        diocsesNameAlt: '',
        province: 'Amalfi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0304',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D056: {
        dioceseId: 'D056',
        diocsesName: 'Capua',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0281',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D057: {
        dioceseId: 'D057',
        diocsesName: 'Carinola',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0280',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D058: {
        dioceseId: 'D058',
        diocsesName: 'Vercelli',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0180',
        shapeFileId2: 'S0566',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D059: {
        dioceseId: 'D059',
        diocsesName: 'Caserta',
        diocsesNameAlt: 'Galazia in Campania',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0284',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D060: {
        dioceseId: 'D060',
        diocsesName: 'Cassano',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0116',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D061: {
        dioceseId: 'D061',
        diocsesName: 'Castellamare',
        diocsesNameAlt: '',
        province: 'Sorrento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0295',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D062: {
        dioceseId: 'D062',
        diocsesName: 'Castellaneta',
        diocsesNameAlt: '',
        province: 'Taranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0051',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1023',
        yearSuppressed: '',
        notes: '',
    },
    D063: {
        dioceseId: 'D063',
        diocsesName: 'Castello',
        diocsesNameAlt: '',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0019',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1451',
        notes: 'Suppressed to Venice in 1451',
    },
    D064: {
        dioceseId: 'D064',
        diocsesName: 'Castro',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0125',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: '',
    },
    D065: {
        dioceseId: 'D065',
        diocsesName: 'Castro',
        diocsesNameAlt: '',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0042',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D066: {
        dioceseId: 'D066',
        diocsesName: 'Catanzaro',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0102',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1121',
        yearSuppressed: '',
        notes: '',
    },
    D067: {
        dioceseId: 'D067',
        diocsesName: 'Cava',
        diocsesNameAlt: 'Badia di Cava',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0316',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1394',
        yearSuppressed: '1465',
        notes: 'abbey (nullius); loses diocesan privileges in 1465',
    },
    D068: {
        dioceseId: 'D068',
        diocsesName: 'Ceneda',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0016',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D069: {
        dioceseId: 'D069',
        diocsesName: 'Cerenza',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0112',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D070: {
        dioceseId: 'D070',
        diocsesName: 'Cervia',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0226',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D071: {
        dioceseId: 'D071',
        diocsesName: 'Cesena',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0233',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D072: {
        dioceseId: 'D072',
        diocsesName: 'Chieti',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0254',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D073: {
        dioceseId: 'D073',
        diocsesName: 'Chioggia',
        diocsesNameAlt: '',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0010',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D074: {
        dioceseId: 'D074',
        diocsesName: 'Chiusi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Siena',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0245',
        shapeFileId2: 'S0924',
        shapeFileId3: 'S0585',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Siena from 1459',
    },
    D075: {
        dioceseId: 'D075',
        diocsesName: 'Abbazia delle Tre Fontane',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0248',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D076: {
        dioceseId: 'D076',
        diocsesName: 'Città di Castello',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0206',
        shapeFileId2: 'S0501',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D077: {
        dioceseId: 'D077',
        diocsesName: 'Novigrad',
        diocsesNameAlt: 'Cittanova',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0002',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: "Aemona, Cittanova d'Istria",
    },
    D078: {
        dioceseId: 'D078',
        diocsesName: 'Civita Castellana',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0141',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United with Orte in 1437 (or perhaps 1439)',
    },
    D079: {
        dioceseId: 'D079',
        diocsesName: 'Comacchio',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0227',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D080: {
        dioceseId: 'D080',
        diocsesName: 'Como',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0160',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '608',
        yearSuppressed: '1751',
        notes: '',
    },
    D081: {
        dioceseId: 'D081',
        diocsesName: 'Concordia',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0024',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D082: {
        dioceseId: 'D082',
        diocsesName: 'Conza',
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0313',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D084: {
        dioceseId: 'D084',
        diocsesName: 'Cosenza',
        diocsesNameAlt: '',
        province: 'Cosenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0104',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1150',
        yearSuppressed: '',
        notes: '',
    },
    D085: {
        dioceseId: 'D085',
        diocsesName: 'Cotrone',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0111',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D086: {
        dioceseId: 'D086',
        diocsesName: 'Cremona',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0166',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D087: {
        dioceseId: 'D087',
        diocsesName: 'Dragonara',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0059',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1039',
        yearSuppressed: '',
        notes: '',
    },
    D088: {
        dioceseId: 'D088',
        diocsesName: 'Faenza',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0230',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'briefly exempted from Ravennan authority from 1099-1119',
    },
    D089: {
        dioceseId: 'D089',
        diocsesName: 'Fano',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0194',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D090: {
        dioceseId: 'D090',
        diocsesName: 'Feltre',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0562',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United with Belluno from 1197 to 1462',
    },
    D091: {
        dioceseId: 'D091',
        diocsesName: 'Ferentino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0157',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D092: {
        dioceseId: 'D092',
        diocsesName: 'Fermo',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0202',
        shapeFileId2: 'S0488',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1320',
        yearSuppressed: '',
        notes: '',
    },
    D093: {
        dioceseId: 'D093',
        diocsesName: 'Ferrara',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0225',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            "Ferrara held exempt status from 774, despite Ravenna's persistent claims",
    },
    D094: {
        dioceseId: 'D094',
        diocsesName: 'Fiesole',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Firenze',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0240',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Made suffragan to Firenze in 1420',
    },
    D095: {
        dioceseId: 'D095',
        diocsesName: 'Fiorentino',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0060',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '1410',
        notes: 'Territory annexed to Lucera',
    },
    D096: {
        dioceseId: 'D096',
        diocsesName: 'Firenze',
        diocsesNameAlt: 'Florence',
        province: '[Holy See]',
        province2: 'Firenze',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0239',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Elevated to metropolitanate in 1420',
    },
    D097: {
        dioceseId: 'D097',
        diocsesName: 'Foligno',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0211',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D098: {
        dioceseId: 'D098',
        diocsesName: 'Fondi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0273',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D099: {
        dioceseId: 'D099',
        diocsesName: 'Forlì',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0231',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D100: {
        dioceseId: 'D100',
        diocsesName: 'Forlimpopoli',
        diocsesNameAlt: 'Bertinoro',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0232',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'See transferred and name changed to Bertinoro in 1360',
    },
    D101: {
        dioceseId: 'D101',
        diocsesName: 'Fossombrone',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0193',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D102: {
        dioceseId: 'D102',
        diocsesName: 'Frigento',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0598',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1466',
        notes: 'United with Avellino in 1466',
    },
    D103: {
        dioceseId: 'D103',
        diocsesName: 'Gaeta',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0274',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D104: {
        dioceseId: 'D104',
        diocsesName: 'Gallipoli',
        diocsesNameAlt: '',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0044',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D105: {
        dioceseId: 'D105',
        diocsesName: 'Genova',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0267',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Raised to archdiocese in 1133',
    },
    D106: {
        dioceseId: 'D106',
        diocsesName: 'Gerace',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0096',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United with Oppido in 1462',
    },
    D107: {
        dioceseId: 'D107',
        diocsesName: 'Grado',
        diocsesNameAlt: 'Venezia',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0008',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1451',
        notes: 'United with Asolo in 1440; suppressed to Venice in 1451',
    },
    D108: {
        dioceseId: 'D108',
        diocsesName: 'Gravina',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0085',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D109: {
        dioceseId: 'D109',
        diocsesName: 'Grosseto',
        diocsesNameAlt: 'Roselle',
        province: '[Holy See]',
        province2: 'Siena',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0246',
        shapeFileId2: 'S0871',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Made suffragan to Siena in 1459',
    },
    D110: {
        dioceseId: 'D110',
        diocsesName: 'Guardialfiera',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0258',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '',
        notes: '',
    },
    D111: {
        dioceseId: 'D111',
        diocsesName: 'Gubbio',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0207',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D112: {
        dioceseId: 'D112',
        diocsesName: 'Imola',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0229',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D113: {
        dioceseId: 'D113',
        diocsesName: 'Ischia',
        diocsesNameAlt: '',
        province: 'Napoli',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0290',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1036',
        yearSuppressed: '',
        notes: '',
    },
    D114: {
        dioceseId: 'D114',
        diocsesName: 'Isola',
        diocsesNameAlt: 'Capo Rizzuto',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0108',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1114',
        yearSuppressed: '',
        notes: '',
    },
    D115: {
        dioceseId: 'D115',
        diocsesName: 'Isernia',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0256',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'aka Isola di Capo Rizzuto',
    },
    D116: {
        dioceseId: 'D116',
        diocsesName: 'Ivrea',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0181',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D117: {
        dioceseId: 'D117',
        diocsesName: 'Jesi',
        diocsesNameAlt: 'Iesi',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0196',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D118: {
        dioceseId: 'D118',
        diocsesName: 'Equilio',
        diocsesNameAlt: 'Jesolo',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0021',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1466',
        notes: 'suppressed to Venezia in 1466; also know as Iesolo',
    },
    D119: {
        dioceseId: 'D119',
        diocsesName: 'Lacedonia',
        diocsesNameAlt: 'Lacedogna',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0311',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1080',
        yearSuppressed: '',
        notes: '',
    },
    D120: {
        dioceseId: 'D120',
        diocsesName: "L'Aquila",
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0251',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1257',
        yearSuppressed: '',
        notes: '',
    },
    D121: {
        dioceseId: 'D121',
        diocsesName: 'Larino',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0261',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D122: {
        dioceseId: 'D122',
        diocsesName: 'Lecce',
        diocsesNameAlt: '',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0046',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1057',
        yearSuppressed: '',
        notes: '',
    },
    D123: {
        dioceseId: 'D123',
        diocsesName: 'Lesina',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0057',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1254',
        yearSuppressed: '',
        notes: 'United with Benevento from 1459-73',
    },
    D124: {
        dioceseId: 'D124',
        diocsesName: 'Lettere',
        diocsesNameAlt: 'Lettere–Gragnano',
        province: 'Amalfi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0296',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D125: {
        dioceseId: 'D125',
        diocsesName: 'Lodi',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0165',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D126: {
        dioceseId: 'D126',
        diocsesName: 'Ljubljana',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Slovenia',
        shapeFileId: 'S0897',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1461',
        yearSuppressed: '',
        notes: '',
    },
    D127: {
        dioceseId: 'D127',
        diocsesName: 'Lucca',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0236',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D128: {
        dioceseId: 'D128',
        diocsesName: 'Lucera',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0064',
        shapeFileId2: 'S0556',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            "Gains territory of Fiorentino in 1410; United with Citta' (Civitate, late San Severo) from 1439-50; briefly united with Tortiboli in 1425",
    },
    D129: {
        dioceseId: 'D129',
        diocsesName: 'Luni and Sarzana',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0268',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1133',
        yearSuppressed: '',
        notes: '',
    },
    D130: {
        dioceseId: 'D130',
        diocsesName: 'Macerata',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0201',
        shapeFileId2: 'S0912',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1320',
        yearSuppressed: '',
        notes:
            'may have taken territory from Recanati as well, with which it was then joined in union from 1356-1586',
    },
    D131: {
        dioceseId: 'D131',
        diocsesName: 'Manfredonia',
        diocsesNameAlt: 'Siponto',
        province: 'Manfredonia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0065',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1258',
        yearSuppressed: '',
        notes: '',
    },
    D132: {
        dioceseId: 'D132',
        diocsesName: 'Mantova',
        diocsesNameAlt: 'Mantua',
        province: 'Aquileia',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0167',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'made exempt in 1452',
    },
    D133: {
        dioceseId: 'D133',
        diocsesName: 'Marsi',
        diocsesNameAlt: 'Avezzano',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0252',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'also known as Pescina (second seat)',
    },
    D134: {
        dioceseId: 'D134',
        diocsesName: 'Marsico Nuovo',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0080',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D135: {
        dioceseId: 'D135',
        diocsesName: 'Martirano',
        diocsesNameAlt: '',
        province: 'Cosenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0103',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1099',
        yearSuppressed: '',
        notes: '',
    },
    D136: {
        dioceseId: 'D136',
        diocsesName: 'Massa Lubrense',
        diocsesNameAlt: '',
        province: 'Sorrento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0303',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1024',
        yearSuppressed: '',
        notes: '',
    },
    D137: {
        dioceseId: 'D137',
        diocsesName: 'Massa Marittima',
        diocsesNameAlt: '',
        province: 'Pisa',
        province2: 'Firenze',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0244',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan to Pisa until 1420, then to Firenze',
    },
    D138: {
        dioceseId: 'D138',
        diocsesName: 'Matera',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0574',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'united with Acerenza in 1203; briefly split from 1440-1444',
    },
    D139: {
        dioceseId: 'D139',
        diocsesName: 'Melfi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0072',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: '',
    },
    D140: {
        dioceseId: 'D140',
        diocsesName: 'Milano',
        diocsesNameAlt: 'Milan',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0161',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D141: {
        dioceseId: 'D141',
        diocsesName: 'Mileto',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0098',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1081',
        yearSuppressed: '',
        notes: '',
    },
    D142: {
        dioceseId: 'D142',
        diocsesName: 'Minori',
        diocsesNameAlt: '',
        province: 'Amalfi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0300',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D143: {
        dioceseId: 'D143',
        diocsesName: 'Modena',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0223',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D144: {
        dioceseId: 'D144',
        diocsesName: 'Mondovì',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0188',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1388',
        yearSuppressed: '',
        notes: '',
    },
    D145: {
        dioceseId: 'D145',
        diocsesName: 'Monopoli',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0049',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1030',
        yearSuppressed: '',
        notes: '',
    },
    D146: {
        dioceseId: 'D146',
        diocsesName: 'Montalcino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0898',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1462',
        yearSuppressed: '',
        notes: 'usually united with Pienza until 1594 (with minor divisions)',
    },
    D147: {
        dioceseId: 'D147',
        diocsesName: 'Monte Cassino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0275',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1326',
        yearSuppressed: '',
        notes: '',
    },
    D148: {
        dioceseId: 'D148',
        diocsesName: 'Montecorvino',
        diocsesNameAlt: 'Monte Corvino',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0062',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '1501',
        notes: 'united aeque principaliter with Volturara in 1434',
    },
    D149: {
        dioceseId: 'D149',
        diocsesName: 'Monte Marano',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0305',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '',
        notes: '',
    },
    D150: {
        dioceseId: 'D150',
        diocsesName: 'Montefeltro',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0189',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D151: {
        dioceseId: 'D151',
        diocsesName: 'Montefiascone',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0589',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1369',
        yearSuppressed: '',
        notes: 'United with Corneto in 1435',
    },
    D152: {
        dioceseId: 'D152',
        diocsesName: 'Montepeloso',
        diocsesNameAlt: '',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0904',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1460',
        yearSuppressed: '',
        notes: 'Created from part of diocese of Andria',
    },
    D153: {
        dioceseId: 'D153',
        diocsesName: 'Monteverde',
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0312',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1049',
        yearSuppressed: '',
        notes: '',
    },
    D155: {
        dioceseId: 'D155',
        diocsesName: 'Muro',
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0076',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1040',
        yearSuppressed: '',
        notes: '',
    },
    D156: {
        dioceseId: 'D156',
        diocsesName: 'Napoli',
        diocsesNameAlt: 'Naples',
        province: 'Napoli',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0289',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D157: {
        dioceseId: 'D157',
        diocsesName: 'Nardò',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0043',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1090',
        yearSuppressed: '',
        notes: 'Erected as diocese from Benedictine abbey in 1413',
    },
    D158: {
        dioceseId: 'D158',
        diocsesName: 'Narni',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0216',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D159: {
        dioceseId: 'D159',
        diocsesName: 'Nepi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0142',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United with Sutri in 1435',
    },
    D160: {
        dioceseId: 'D160',
        diocsesName: 'Nicastro',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0101',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D161: {
        dioceseId: 'D161',
        diocsesName: 'Nicotera',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0581',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'suppressed 1304-1392 to Mileto and then to Reggio; territory before suppression uncertain',
    },
    D162: {
        dioceseId: 'D162',
        diocsesName: 'Nocera',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0208',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D163: {
        dioceseId: 'D163',
        diocsesName: 'Nocera Inferiore',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0899',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D164: {
        dioceseId: 'D164',
        diocsesName: 'Nola',
        diocsesNameAlt: '',
        province: 'Napoli',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0291',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D165: {
        dioceseId: 'D165',
        diocsesName: 'Noli',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0266',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1239',
        yearSuppressed: '',
        notes: 'Perhaps erected in 1245 rather than 1239',
    },
    D166: {
        dioceseId: 'D166',
        diocsesName: 'Novara',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0179',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D167: {
        dioceseId: 'D167',
        diocsesName: 'Nusco',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0306',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1076',
        yearSuppressed: '',
        notes: '',
    },
    D168: {
        dioceseId: 'D168',
        diocsesName: 'Oppido',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0097',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1025',
        yearSuppressed: '',
        notes: 'United with Gerace in 1462',
    },
    D169: {
        dioceseId: 'D169',
        diocsesName: 'Orte',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0140',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: "United with Civita' Castellana in 1439 (or perhaps 1437)",
    },
    D170: {
        dioceseId: 'D170',
        diocsesName: 'Orvieto',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0212',
        shapeFileId2: 'S0544',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D171: {
        dioceseId: 'D171',
        diocsesName: 'Osimo',
        diocsesNameAlt: 'Cingoli',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0199',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1240',
        yearSuppressed: '',
        notes:
            'suppressed after a rebellion in 1240; restored in 1246, having lost some territory to the newly-created Recanati; seat moved to Cingoli (Santa Maria di Cingoli) after 1320 rebellion and renamed to Osimo and Cingola, seat & name restored to Osimo in 1367',
    },
    D172: {
        dioceseId: 'D172',
        diocsesName: 'Ostia',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0148',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'United w/ Velletri 1150-1914',
    },
    D173: {
        dioceseId: 'D173',
        diocsesName: 'Ostuni',
        diocsesNameAlt: '',
        province: 'Brindisi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0048',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: '',
    },
    D174: {
        dioceseId: 'D174',
        diocsesName: 'Otranto',
        diocsesNameAlt: '',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0045',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D175: {
        dioceseId: 'D175',
        diocsesName: 'Padova',
        diocsesNameAlt: 'Padua',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0017',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D176: {
        dioceseId: 'D176',
        diocsesName: 'Palestrina',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0151',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D177: {
        dioceseId: 'D177',
        diocsesName: 'Poreč',
        diocsesNameAlt: 'Parenzo ',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0003',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D178: {
        dioceseId: 'D178',
        diocsesName: 'Parma',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0221',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D179: {
        dioceseId: 'D179',
        diocsesName: 'Pavia',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0164',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D180: {
        dioceseId: 'D180',
        diocsesName: 'Pićan',
        diocsesNameAlt: 'Pedena',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0005',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D181: {
        dioceseId: 'D181',
        diocsesName: 'Penne',
        diocsesNameAlt: 'Penne-Atri',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0250',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            "United with Atri from that diocese's creation in 1251 (represented together)",
    },
    D182: {
        dioceseId: 'D182',
        diocsesName: 'Perugia',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0209',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D183: {
        dioceseId: 'D183',
        diocsesName: 'Pesaro',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0191',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D184: {
        dioceseId: 'D184',
        diocsesName: 'Piacenza',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0220',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D185: {
        dioceseId: 'D185',
        diocsesName: 'Pienza',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0900',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1462',
        yearSuppressed: '',
        notes:
            'usually united with Montalcino until 1594 (with minor divisions)',
    },
    D186: {
        dioceseId: 'D186',
        diocsesName: 'Pisa',
        diocsesNameAlt: '',
        province: 'Pisa',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0238',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D187: {
        dioceseId: 'D187',
        diocsesName: 'Pistoia',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Firenze',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0237',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Firenze from 1420',
    },
    D188: {
        dioceseId: 'D188',
        diocsesName: 'Pula',
        diocsesNameAlt: 'Pola',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0004',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D189: {
        dioceseId: 'D189',
        diocsesName: 'Policastro',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0317',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1058',
        yearSuppressed: '',
        notes: '',
    },
    D190: {
        dioceseId: 'D190',
        diocsesName: 'Porto',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0145',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D191: {
        dioceseId: 'D191',
        diocsesName: 'Potenza',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0077',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D192: {
        dioceseId: 'D192',
        diocsesName: 'Pozzuoli',
        diocsesNameAlt: '',
        province: 'Napoli',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0288',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D193: {
        dioceseId: 'D193',
        diocsesName: 'Rapolla',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0075',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '',
        notes: '',
    },
    D194: {
        dioceseId: 'D194',
        diocsesName: 'Ravello',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0298',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1086',
        yearSuppressed: '',
        notes: '',
    },
    D195: {
        dioceseId: 'D195',
        diocsesName: 'Ravenna',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0228',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D196: {
        dioceseId: 'D196',
        diocsesName: 'Recanati',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0200',
        shapeFileId2: 'S0454',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1239',
        yearSuppressed: '1320',
        notes:
            'Transferred to Macerata in 1320; in union with Macerata from its 1356 restablishment',
    },
    D197: {
        dioceseId: 'D197',
        diocsesName: 'Reggio-Calabria',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0095',
        shapeFileId2: 'S0617',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D198: {
        dioceseId: 'D198',
        diocsesName: 'Reggio-Emilia',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0222',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D199: {
        dioceseId: 'D199',
        diocsesName: 'Rieti',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0218',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D200: {
        dioceseId: 'D200',
        diocsesName: 'Rimini',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0235',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D201: {
        dioceseId: 'D201',
        diocsesName: 'Rossano',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Rossano',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0115',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Elevated to Archbishopric (without suffragans) in 1460',
    },
    D202: {
        dioceseId: 'D202',
        diocsesName: 'Sabina',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0144',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D203: {
        dioceseId: 'D203',
        diocsesName: 'Salerno',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0294',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D204: {
        dioceseId: 'D204',
        diocsesName: 'San Leone',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0110',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '999',
        yearSuppressed: '1501',
        notes: 'first Latin bishop in 1322 (previously Byzantine)',
    },
    D205: {
        dioceseId: 'D205',
        diocsesName: 'San Marco',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0106',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1159',
        yearSuppressed: '',
        notes: '',
    },
    D206: {
        dioceseId: 'D206',
        diocsesName: "Sant'Agata de' Goti",
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0285',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D207: {
        dioceseId: 'D207',
        diocsesName: "Sant'Angelo de' Lombardi",
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0307',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1080',
        yearSuppressed: '',
        notes: '',
    },
    D208: {
        dioceseId: 'D208',
        diocsesName: 'Santa Severina',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0109',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D209: {
        dioceseId: 'D209',
        diocsesName: 'Sarno',
        diocsesNameAlt: '',
        province: 'Salerno',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0293',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1000',
        yearSuppressed: '',
        notes: '',
    },
    D210: {
        dioceseId: 'D210',
        diocsesName: 'Sarsina',
        diocsesNameAlt: '',
        province: 'Ravenna',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0234',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D211: {
        dioceseId: 'D211',
        diocsesName: 'Satriano',
        diocsesNameAlt: '',
        province: 'Conza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0079',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1098',
        yearSuppressed: '',
        notes: '',
    },
    D212: {
        dioceseId: 'D212',
        diocsesName: 'Savona',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0265',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1239',
        notes: '',
    },
    D213: {
        dioceseId: 'D213',
        diocsesName: 'Scala',
        diocsesNameAlt: '',
        province: 'Amalfi',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0299',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D214: {
        dioceseId: 'D214',
        diocsesName: 'Segni',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0154',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D215: {
        dioceseId: 'D215',
        diocsesName: 'Sessa',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0276',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D216: {
        dioceseId: 'D216',
        diocsesName: 'Siena',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Siena',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0243',
        shapeFileId2: 'S0870',
        shapeFileId3: 'S0881',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Elevated to metropolitanate in 1459',
    },
    D217: {
        dioceseId: 'D217',
        diocsesName: 'Sinigaglia',
        diocsesNameAlt: 'Senigallia',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0195',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'seat moved to Corinaldo in the 14th century',
    },
    D218: {
        dioceseId: 'D218',
        diocsesName: 'Sora',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0271',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D219: {
        dioceseId: 'D219',
        diocsesName: 'Sorrento',
        diocsesNameAlt: '',
        province: 'Sorrento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0302',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D220: {
        dioceseId: 'D220',
        diocsesName: 'Sovanna',
        diocsesNameAlt: 'Sovana',
        province: '[Holy See]',
        province2: 'Siena',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0247',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Siena from 1459',
    },
    D221: {
        dioceseId: 'D221',
        diocsesName: 'Spoleto',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0214',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D222: {
        dioceseId: 'D222',
        diocsesName: 'Squillace',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0100',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D223: {
        dioceseId: 'D223',
        diocsesName: 'Strongoli',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0113',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D224: {
        dioceseId: 'D224',
        diocsesName: 'Sutri',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0143',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1435',
        notes: '',
    },
    D225: {
        dioceseId: 'D225',
        diocsesName: 'Taranto',
        diocsesNameAlt: '',
        province: 'Taranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0050',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D226: {
        dioceseId: 'D226',
        diocsesName: 'Teano',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0277',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D227: {
        dioceseId: 'D227',
        diocsesName: 'Telese',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0279',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D228: {
        dioceseId: 'D228',
        diocsesName: 'Teramo',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0249',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D229: {
        dioceseId: 'D229',
        diocsesName: 'Termoli',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0259',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D230: {
        dioceseId: 'D230',
        diocsesName: 'Terni',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0217',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1218',
        yearSuppressed: '',
        notes: '',
    },
    D231: {
        dioceseId: 'D231',
        diocsesName: 'Terracina',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0156',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D232: {
        dioceseId: 'D232',
        diocsesName: 'Tertiveri',
        diocsesNameAlt: 'Tortiboli',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0063',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '1425',
        notes: 'Incorporated into Lucera in 1425',
    },
    D233: {
        dioceseId: 'D233',
        diocsesName: 'Tivoli',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0147',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D234: {
        dioceseId: 'D234',
        diocsesName: 'Todi',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0213',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D235: {
        dioceseId: 'D235',
        diocsesName: 'Torcello',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: 'Grado',
        province3: 'Venezia',
        countryModern: 'Italy',
        shapeFileId: 'S0020',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'transferred to Grado by the 13th century',
    },
    D236: {
        dioceseId: 'D236',
        diocsesName: 'Torino',
        diocsesNameAlt: 'Turin',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0182',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D237: {
        dioceseId: 'D237',
        diocsesName: 'Cortona',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0270',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1325',
        yearSuppressed: '',
        notes: '',
    },
    D238: {
        dioceseId: 'D238',
        diocsesName: 'Tortona',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0185',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D239: {
        dioceseId: 'D239',
        diocsesName: 'Trani',
        diocsesNameAlt: '',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0090',
        shapeFileId2: 'S0573',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Salpi (prov. Bari) united to it in 1425',
    },
    D240: {
        dioceseId: 'D240',
        diocsesName: 'Trento',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0014',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D241: {
        dioceseId: 'D241',
        diocsesName: 'Trevico',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0308',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D242: {
        dioceseId: 'D242',
        diocsesName: 'Treviso',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0018',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D243: {
        dioceseId: 'D243',
        diocsesName: 'Tricarico',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0081',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D244: {
        dioceseId: 'D244',
        diocsesName: 'Trieste',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0007',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D245: {
        dioceseId: 'D245',
        diocsesName: 'Trivento',
        diocsesNameAlt: '',
        province: 'Benevento',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0257',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Exempt from 1474',
    },
    D246: {
        dioceseId: 'D246',
        diocsesName: 'Troia',
        diocsesNameAlt: 'Troja',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0067',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D247: {
        dioceseId: 'D247',
        diocsesName: 'Tropea',
        diocsesNameAlt: '',
        province: 'Reggio-Calabria',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0099',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D248: {
        dioceseId: 'D248',
        diocsesName: 'Tuscania',
        diocsesNameAlt: 'Tusculum',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0138',
        shapeFileId2: 'S0586',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1192',
        yearSuppressed: '',
        notes:
            'in union with Viterbo from 1192; lost territory in 1369 with erection of Montefiascone, and in 1435 with reestablishment of Tarquinia',
    },
    D249: {
        dioceseId: 'D249',
        diocsesName: 'Ugento',
        diocsesNameAlt: '',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0041',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D250: {
        dioceseId: 'D250',
        diocsesName: 'Casale Monferrato',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0159',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1474',
        yearSuppressed: '',
        notes: '',
    },
    D251: {
        dioceseId: 'D251',
        diocsesName: 'Umbriatico',
        diocsesNameAlt: '',
        province: 'Santa Severina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0114',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1030',
        yearSuppressed: '',
        notes: '',
    },
    D252: {
        dioceseId: 'D252',
        diocsesName: 'Urbino',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0190',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D253: {
        dioceseId: 'D253',
        diocsesName: 'Sulmona-Valva',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0253',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'united 1053-1986',
    },
    D254: {
        dioceseId: 'D254',
        diocsesName: 'Venafro',
        diocsesNameAlt: '',
        province: 'Capua',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0255',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '',
        notes: '',
    },
    D255: {
        dioceseId: 'D255',
        diocsesName: 'Venezia',
        diocsesNameAlt: 'Venice',
        province: 'Grado',
        province2: 'Venezia',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0559',
        shapeFileId2: 'S0560',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1451',
        yearSuppressed: '',
        notes:
            'Elevated to patriarchate in 1451 (Grado being thereby suppressed); United with Castello and Iesolo in 1466',
    },
    D256: {
        dioceseId: 'D256',
        diocsesName: 'Venosa',
        diocsesNameAlt: '',
        province: 'Acerenza',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0074',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D257: {
        dioceseId: 'D257',
        diocsesName: 'Ventimiglia',
        diocsesNameAlt: '',
        province: 'Milano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0263',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D258: {
        dioceseId: 'D258',
        diocsesName: 'Velletri',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0153',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D259: {
        dioceseId: 'D259',
        diocsesName: 'Veroli',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0158',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D260: {
        dioceseId: 'D260',
        diocsesName: 'Verona',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0013',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D261: {
        dioceseId: 'D261',
        diocsesName: 'Vicenza',
        diocsesNameAlt: '',
        province: 'Aquileia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0012',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D262: {
        dioceseId: 'D262',
        diocsesName: 'Vico Equense',
        diocsesNameAlt: '',
        province: 'Sorrento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0301',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D263: {
        dioceseId: 'D263',
        diocsesName: 'Vieste',
        diocsesNameAlt: '',
        province: 'Manfredonia',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0066',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D264: {
        dioceseId: 'D264',
        diocsesName: 'Viterbo',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0139',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1192',
        yearSuppressed: '1503',
        notes: 'From 10 February - 17 October 1449, united with Bagnoregio',
    },
    D265: {
        dioceseId: 'D265',
        diocsesName: 'Volterra',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0241',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D266: {
        dioceseId: 'D266',
        diocsesName: 'Volturara',
        diocsesNameAlt: 'Vulturara',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0061',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1059',
        yearSuppressed: '',
        notes: 'united aeque principaliter with Montecorvino in 1434',
    },
    D267: {
        dioceseId: 'D267',
        diocsesName: 'Agrigento',
        diocsesNameAlt: '',
        province: 'Palermo',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0175',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1088',
        yearSuppressed: '',
        notes: '',
    },
    D268: {
        dioceseId: 'D268',
        diocsesName: 'Catania',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0176',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1092',
        yearSuppressed: '',
        notes: '',
    },
    D269: {
        dioceseId: 'D269',
        diocsesName: 'Cefalù',
        diocsesNameAlt: '',
        province: 'Messina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0171',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1131',
        yearSuppressed: '',
        notes: '',
    },
    D270: {
        dioceseId: 'D270',
        diocsesName: 'Mazara',
        diocsesNameAlt: '',
        province: 'Palermo',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0174',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1093',
        yearSuppressed: '',
        notes: '',
    },
    D271: {
        dioceseId: 'D271',
        diocsesName: 'Messina',
        diocsesNameAlt: '',
        province: 'Messina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0169',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D272: {
        dioceseId: 'D272',
        diocsesName: 'Monreale',
        diocsesNameAlt: '',
        province: 'Monreale',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0173',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1176',
        yearSuppressed: '',
        notes: '',
    },
    D273: {
        dioceseId: 'D273',
        diocsesName: 'Palermo',
        diocsesNameAlt: '',
        province: 'Palermo',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0172',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D274: {
        dioceseId: 'D274',
        diocsesName: 'Patti',
        diocsesNameAlt: '',
        province: 'Messina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0170',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '#REF!',
        yearSuppressed: '',
        notes:
            'erected as diocese of Patti and Lipari in 1157, from Messina; separated from Lipari in 1399',
    },
    D275: {
        dioceseId: 'D275',
        diocsesName: 'Siracusa',
        diocsesNameAlt: 'Syracuse',
        province: 'Monreale',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0177',
        shapeFileId2: ' ',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1086',
        yearSuppressed: '',
        notes: '',
    },
    D276: {
        dioceseId: 'D276',
        diocsesName: 'Ales',
        diocsesNameAlt: '',
        province: 'Oristano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0132',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: '',
    },
    D277: {
        dioceseId: 'D277',
        diocsesName: 'Bisarcio',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0580',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D278: {
        dioceseId: 'D278',
        diocsesName: 'Bosa',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0121',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D279: {
        dioceseId: 'D279',
        diocsesName: 'Cagliari',
        diocsesNameAlt: '',
        province: 'Cagliari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0135',
        shapeFileId2: 'S0582',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1420',
        notes: '',
    },
    D280: {
        dioceseId: 'D280',
        diocsesName: 'Castro',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0136',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: '',
    },
    D282: {
        dioceseId: 'D282',
        diocsesName: 'Dolia',
        diocsesNameAlt: 'Doglia',
        province: 'Cagliari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0133',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: 'suppressed 1503; united to Cagliari',
    },
    D283: {
        dioceseId: 'D283',
        diocsesName: 'Galtelli',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0126',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1120',
        yearSuppressed: '1495',
        notes: 'suppressed to Cagliari in 1495',
    },
    D284: {
        dioceseId: 'D284',
        diocsesName: 'Oristano',
        diocsesNameAlt: '',
        province: 'Oristano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0128',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1093',
        yearSuppressed: '1503',
        notes: '',
    },
    D285: {
        dioceseId: 'D285',
        diocsesName: 'Ottana',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0127',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1065',
        yearSuppressed: '',
        notes: '',
    },
    D286: {
        dioceseId: 'D286',
        diocsesName: 'Ploaghe',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0123',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1090',
        yearSuppressed: '1503',
        notes: 'Incorporated into Sassari in 1503',
    },
    D287: {
        dioceseId: 'D287',
        diocsesName: 'Santa Giusta',
        diocsesNameAlt: '',
        province: 'Oristano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0129',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1093',
        yearSuppressed: '1503',
        notes: 'Incorporated into Oristano in 1503',
    },
    D288: {
        dioceseId: 'D288',
        diocsesName: 'Sassari',
        diocsesNameAlt: 'Torres',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0118',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes:
            'seat moved from Torres to Sassari 1250 but name not changged until 1441',
    },
    D289: {
        dioceseId: 'D289',
        diocsesName: 'Sorres',
        diocsesNameAlt: 'Sorra',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0122',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '1503',
        notes: '',
    },
    D290: {
        dioceseId: 'D290',
        diocsesName: 'Suelli',
        diocsesNameAlt: '',
        province: 'Cagliari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0130',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1420',
        notes: 'United with Cagliari in 1420',
    },
    D292: {
        dioceseId: 'D292',
        diocsesName: 'Tempio',
        diocsesNameAlt: '',
        province: 'Pisa',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0120',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1490',
        notes:
            'originally exempt; made suffragan of Pisa in 1138; United with Ampurias from 1503; also known as Gallura',
    },
    D293: {
        dioceseId: 'D293',
        diocsesName: 'Terralba',
        diocsesNameAlt: '',
        province: 'Oristano',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0131',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: 'Incorporated into Ales in 1503',
    },
    D294: {
        dioceseId: 'D294',
        diocsesName: 'Massa Carrara',
        diocsesNameAlt: '',
        province: '',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0423',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'territorial abbey',
    },
    D295: {
        dioceseId: 'D295',
        diocsesName: 'Canterbury',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0352',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '597',
        yearSuppressed: '',
        notes: '',
    },
    D296: {
        dioceseId: 'D296',
        diocsesName: 'Rochester',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0353',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '604',
        yearSuppressed: '',
        notes: '',
    },
    D297: {
        dioceseId: 'D297',
        diocsesName: 'London',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0354',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '604',
        yearSuppressed: '',
        notes: '',
    },
    D298: {
        dioceseId: 'D298',
        diocsesName: 'York',
        diocsesNameAlt: '',
        province: 'York',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0339',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '626',
        yearSuppressed: '',
        notes: '',
    },
    D299: {
        dioceseId: 'D299',
        diocsesName: 'Winchester',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0350',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D300: {
        dioceseId: 'D300',
        diocsesName: 'Coventry & Lichfield',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0340',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '669',
        yearSuppressed: '',
        notes: '',
    },
    D301: {
        dioceseId: 'D301',
        diocsesName: 'Durham',
        diocsesNameAlt: '',
        province: 'York',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0338',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '995',
        yearSuppressed: '',
        notes: '',
    },
    D302: {
        dioceseId: 'D302',
        diocsesName: 'Norwich',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0356',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1091',
        yearSuppressed: '',
        notes: '',
    },
    D303: {
        dioceseId: 'D303',
        diocsesName: 'Hereford',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0344',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '676',
        yearSuppressed: '',
        notes: '',
    },
    D304: {
        dioceseId: 'D304',
        diocsesName: 'Lincoln',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0357',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1072',
        yearSuppressed: '',
        notes: '',
    },
    D305: {
        dioceseId: 'D305',
        diocsesName: 'Worcester',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0343',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '680',
        yearSuppressed: '',
        notes: '',
    },
    D306: {
        dioceseId: 'D306',
        diocsesName: 'Chichester',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0351',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1075',
        yearSuppressed: '',
        notes: '',
    },
    D307: {
        dioceseId: 'D307',
        diocsesName: 'Salisbury',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0348',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1078',
        yearSuppressed: '',
        notes: '',
    },
    D308: {
        dioceseId: 'D308',
        diocsesName: 'Exeter',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0349',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: '',
    },
    D309: {
        dioceseId: 'D309',
        diocsesName: 'Bath',
        diocsesNameAlt: 'Bath & Wells',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0347',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '909',
        yearSuppressed: '',
        notes: '',
    },
    D310: {
        dioceseId: 'D310',
        diocsesName: 'Ely',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0355',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1108',
        yearSuppressed: '',
        notes: '',
    },
    D311: {
        dioceseId: 'D311',
        diocsesName: 'Carlisle',
        diocsesNameAlt: '',
        province: 'York',
        province2: '',
        province3: '',
        countryModern: 'England',
        shapeFileId: 'S0337',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1133',
        yearSuppressed: '',
        notes: '',
    },
    D312: {
        dioceseId: 'D312',
        diocsesName: 'Sodor and Man',
        diocsesNameAlt: 'Isles',
        province: 'Trondheim',
        province2: 'Canterbury',
        province3: '',
        countryModern: '[Scotland]',
        shapeFileId: 'S0320',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1154',
        yearSuppressed: '',
        notes:
            'part of the province of Trondheim/Nidaros until 1334, when it becomes suffragan of Canterbury',
    },
    D313: {
        dioceseId: 'D313',
        diocsesName: 'Llandaff',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'Wales',
        shapeFileId: 'S0346',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D314: {
        dioceseId: 'D314',
        diocsesName: 'Bangor',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'Wales',
        shapeFileId: 'S0342',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D315: {
        dioceseId: 'D315',
        diocsesName: "St. David's",
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'Wales',
        shapeFileId: 'S0345',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D316: {
        dioceseId: 'D316',
        diocsesName: 'St. Asaph',
        diocsesNameAlt: '',
        province: 'Canterbury',
        province2: '',
        province3: '',
        countryModern: 'Wales',
        shapeFileId: 'S0341',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D317: {
        dioceseId: 'D317',
        diocsesName: 'Aberdeen',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0323',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1125',
        yearSuppressed: '',
        notes: '',
    },
    D318: {
        dioceseId: 'D318',
        diocsesName: 'Argyll',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0324',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1183',
        yearSuppressed: '',
        notes: '',
    },
    D319: {
        dioceseId: 'D319',
        diocsesName: 'Brechin',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0326',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1150',
        yearSuppressed: '',
        notes: '',
    },
    D320: {
        dioceseId: 'D320',
        diocsesName: 'Caithness',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0319',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1146',
        yearSuppressed: '',
        notes: '',
    },
    D321: {
        dioceseId: 'D321',
        diocsesName: 'Dunblane',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0327',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1155',
        yearSuppressed: '',
        notes: '',
    },
    D322: {
        dioceseId: 'D322',
        diocsesName: 'Dunkeld',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0325',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1114',
        yearSuppressed: '',
        notes: '',
    },
    D324: {
        dioceseId: 'D324',
        diocsesName: 'Galloway',
        diocsesNameAlt: '',
        province: 'York',
        province2: 'Scotland',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0330',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1128',
        yearSuppressed: '',
        notes:
            'part of the province of York until 1355, thereafter functions as part of Scottish church',
    },
    D325: {
        dioceseId: 'D325',
        diocsesName: 'Glasgow',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0329',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1114',
        yearSuppressed: '',
        notes: '',
    },
    D326: {
        dioceseId: 'D326',
        diocsesName: 'Moray',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0322',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '',
        notes: '',
    },
    D327: {
        dioceseId: 'D327',
        diocsesName: 'Orkney',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: 'Scotland',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0318',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1055',
        yearSuppressed: '',
        notes:
            'suffragan of York until 1152; then (nominal) suffragan of Nidaros until 1469, when transferred to Scotland',
    },
    D328: {
        dioceseId: 'D328',
        diocsesName: 'Ross',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0321',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1152',
        yearSuppressed: '',
        notes: '',
    },
    D329: {
        dioceseId: 'D329',
        diocsesName: 'St. Andrews',
        diocsesNameAlt: '',
        province: 'Scotland',
        province2: '',
        province3: '',
        countryModern: 'Scotland',
        shapeFileId: 'S0328',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '850',
        yearSuppressed: '',
        notes: '',
    },
    D330: {
        dioceseId: 'D330',
        diocsesName: 'Aix',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0618',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D331: {
        dioceseId: 'D331',
        diocsesName: 'Apt',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0619',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D332: {
        dioceseId: 'D332',
        diocsesName: 'Frejus',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0620',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D333: {
        dioceseId: 'D333',
        diocsesName: 'Gap',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0621',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D334: {
        dioceseId: 'D334',
        diocsesName: 'Riez',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0622',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D335: {
        dioceseId: 'D335',
        diocsesName: 'Sisteron',
        diocsesNameAlt: '',
        province: 'Aix',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0623',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D337: {
        dioceseId: 'D337',
        diocsesName: 'Arles',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0625',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D338: {
        dioceseId: 'D338',
        diocsesName: 'Marseille',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0626',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D339: {
        dioceseId: 'D339',
        diocsesName: 'Orange',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0627',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D340: {
        dioceseId: 'D340',
        diocsesName: 'Saint-Paul-Trois-Châteaux',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0628',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D341: {
        dioceseId: 'D341',
        diocsesName: 'Toulon',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0629',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D342: {
        dioceseId: 'D342',
        diocsesName: 'Avignon',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: 'Avignon',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0630',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Raised to Metropolitan in 1475',
    },
    D343: {
        dioceseId: 'D343',
        diocsesName: 'Carpentras',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: 'Avignon',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0631',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Avignon from 1475',
    },
    D344: {
        dioceseId: 'D344',
        diocsesName: 'Cavaillon',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: 'Avignon',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0632',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Avignon from 1475',
    },
    D345: {
        dioceseId: 'D345',
        diocsesName: 'Vaison',
        diocsesNameAlt: '',
        province: 'Arles',
        province2: 'Avignon',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0633',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Suffragan of Avignon from 1475',
    },
    D350: {
        dioceseId: 'D350',
        diocsesName: 'Auch',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0634',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D351: {
        dioceseId: 'D351',
        diocsesName: 'Aire',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0635',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D352: {
        dioceseId: 'D352',
        diocsesName: 'Bazas',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0636',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D353: {
        dioceseId: 'D353',
        diocsesName: 'Dax',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0637',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D354: {
        dioceseId: 'D354',
        diocsesName: 'Bayonne',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0638',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D355: {
        dioceseId: 'D355',
        diocsesName: 'Lectoure',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0639',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D356: {
        dioceseId: 'D356',
        diocsesName: 'Lescar',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0640',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D357: {
        dioceseId: 'D357',
        diocsesName: 'Oloron',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0641',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D358: {
        dioceseId: 'D358',
        diocsesName: 'Saint-Bertrand-de-Comminges',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0642',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D359: {
        dioceseId: 'D359',
        diocsesName: 'Couserans',
        diocsesNameAlt: 'Saint-Lizier',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0643',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D360: {
        dioceseId: 'D360',
        diocsesName: 'Tarbes',
        diocsesNameAlt: '',
        province: 'Auch',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0644',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D361: {
        dioceseId: 'D361',
        diocsesName: 'Besançon',
        diocsesNameAlt: '',
        province: 'Besancon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0645',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D362: {
        dioceseId: 'D362',
        diocsesName: 'Basel',
        diocsesNameAlt: '',
        province: 'Besancon',
        province2: '',
        province3: '',
        countryModern: 'Switzerland',
        shapeFileId: 'S0646',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D363: {
        dioceseId: 'D363',
        diocsesName: 'Belley',
        diocsesNameAlt: '',
        province: 'Besancon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0647',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D364: {
        dioceseId: 'D364',
        diocsesName: 'Lausanne',
        diocsesNameAlt: '',
        province: 'Besancon',
        province2: '',
        province3: '',
        countryModern: 'Switzerland',
        shapeFileId: 'S0447',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D365: {
        dioceseId: 'D365',
        diocsesName: 'Bordeaux',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0648',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D366: {
        dioceseId: 'D366',
        diocsesName: 'Agen',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0649',
        shapeFileId2: 'S0755',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D367: {
        dioceseId: 'D367',
        diocsesName: 'Condom',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0650',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D368: {
        dioceseId: 'D368',
        diocsesName: 'Angoulême',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0651',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D369: {
        dioceseId: 'D369',
        diocsesName: 'Périgueux',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0652',
        shapeFileId2: 'S0754',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D370: {
        dioceseId: 'D370',
        diocsesName: 'Sarlat',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0653',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D371: {
        dioceseId: 'D371',
        diocsesName: 'Poitiers',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0654',
        shapeFileId2: 'S0752',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D372: {
        dioceseId: 'D372',
        diocsesName: 'Luçon',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0655',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Poitiers',
    },
    D373: {
        dioceseId: 'D373',
        diocsesName: 'Maillezais',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0656',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D374: {
        dioceseId: 'D374',
        diocsesName: 'Saintes',
        diocsesNameAlt: '',
        province: 'Bordeaux',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0657',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D375: {
        dioceseId: 'D375',
        diocsesName: 'Bourges',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0658',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D376: {
        dioceseId: 'D376',
        diocsesName: 'Clermont',
        diocsesNameAlt: 'Clermont-Ferrand',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0659',
        shapeFileId2: 'S0760',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Originally Auvergne, renamed 1160',
    },
    D377: {
        dioceseId: 'D377',
        diocsesName: 'Saint-Flour',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0660',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D378: {
        dioceseId: 'D378',
        diocsesName: 'Limoges',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0661',
        shapeFileId2: 'S0753',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D379: {
        dioceseId: 'D379',
        diocsesName: 'Tulle',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0662',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D380: {
        dioceseId: 'D380',
        diocsesName: 'Le Puy',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0663',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D381: {
        dioceseId: 'D381',
        diocsesName: 'Albi',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0664',
        shapeFileId2: 'S0758',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D382: {
        dioceseId: 'D382',
        diocsesName: 'Castres',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0665',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D383: {
        dioceseId: 'D383',
        diocsesName: 'Cahors',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0666',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D384: {
        dioceseId: 'D384',
        diocsesName: 'Mende',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0667',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D385: {
        dioceseId: 'D385',
        diocsesName: 'Rodez',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0668',
        shapeFileId2: 'S0759',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D386: {
        dioceseId: 'D386',
        diocsesName: 'Vabres',
        diocsesNameAlt: '',
        province: 'Bourges',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0669',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D387: {
        dioceseId: 'D387',
        diocsesName: 'Embrun',
        diocsesNameAlt: '',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0670',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D388: {
        dioceseId: 'D388',
        diocsesName: 'Digne',
        diocsesNameAlt: '',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0671',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D389: {
        dioceseId: 'D389',
        diocsesName: 'Glandèves',
        diocsesNameAlt: 'Entrevaux',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0672',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D391: {
        dioceseId: 'D391',
        diocsesName: 'Grasse',
        diocsesNameAlt: 'Antibes',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0674',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'renamed Antibes in 1244',
    },
    D392: {
        dioceseId: 'D392',
        diocsesName: 'Nice',
        diocsesNameAlt: '',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0675',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D393: {
        dioceseId: 'D393',
        diocsesName: 'Senez',
        diocsesNameAlt: '',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0676',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D394: {
        dioceseId: 'D394',
        diocsesName: 'Vence',
        diocsesNameAlt: '',
        province: 'Embrun',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0677',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D395: {
        dioceseId: 'D395',
        diocsesName: 'Lyon',
        diocsesNameAlt: '',
        province: 'Lyon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0678',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D396: {
        dioceseId: 'D396',
        diocsesName: 'Autun',
        diocsesNameAlt: '',
        province: 'Lyon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0679',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D397: {
        dioceseId: 'D397',
        diocsesName: 'Langres',
        diocsesNameAlt: '',
        province: 'Lyon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0680',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D398: {
        dioceseId: 'D398',
        diocsesName: 'Chalon-sur-Saône',
        diocsesNameAlt: '',
        province: 'Lyon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0681',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D399: {
        dioceseId: 'D399',
        diocsesName: 'Mâcon',
        diocsesNameAlt: '',
        province: 'Lyon',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0682',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D400: {
        dioceseId: 'D400',
        diocsesName: 'Narbonne',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0683',
        shapeFileId2: 'S0757',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D401: {
        dioceseId: 'D401',
        diocsesName: 'Alet',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0684',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Narbonne',
    },
    D402: {
        dioceseId: 'D402',
        diocsesName: 'Carcassonne',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0685',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D403: {
        dioceseId: 'D403',
        diocsesName: 'Maguelonne',
        diocsesNameAlt: 'Maguelone',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0686',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D404: {
        dioceseId: 'D404',
        diocsesName: 'Elne',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0553',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D405: {
        dioceseId: 'D405',
        diocsesName: 'Saint-Pons',
        diocsesNameAlt: 'Saint-Pons-de-Thomières',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0687',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: '',
    },
    D406: {
        dioceseId: 'D406',
        diocsesName: 'Agde',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0688',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D407: {
        dioceseId: 'D407',
        diocsesName: 'Béziers',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0689',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D408: {
        dioceseId: 'D408',
        diocsesName: 'Lodève ',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0690',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D409: {
        dioceseId: 'D409',
        diocsesName: 'Nîmes',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0691',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D410: {
        dioceseId: 'D410',
        diocsesName: 'Uzès',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0692',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D411: {
        dioceseId: 'D411',
        diocsesName: 'Pamiers',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: 'Toulouse',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0693',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1295',
        yearSuppressed: '1801',
        notes: 'established from Toulouse in 1295',
    },
    D412: {
        dioceseId: 'D412',
        diocsesName: 'Toulouse',
        diocsesNameAlt: '',
        province: 'Narbonne',
        province2: 'Toulouse',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0694',
        shapeFileId2: 'S0756',
        shapeFileId3: 'S0761',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D414: {
        dioceseId: 'D414',
        diocsesName: 'Lavaur',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0696',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Toulouse',
    },
    D415: {
        dioceseId: 'D415',
        diocsesName: 'Lombez',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0697',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Toulouse',
    },
    D416: {
        dioceseId: 'D416',
        diocsesName: 'Montauban',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0698',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Toulouse and Cahors',
    },
    D418: {
        dioceseId: 'D418',
        diocsesName: 'Mirepoix',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0700',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Pamiers',
    },
    D419: {
        dioceseId: 'D419',
        diocsesName: 'Rieux',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0701',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Pamiers',
    },
    D420: {
        dioceseId: 'D420',
        diocsesName: 'Saint-Papoul',
        diocsesNameAlt: '',
        province: 'Toulouse',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0702',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1317',
        yearSuppressed: '',
        notes: 'established from Toulouse',
    },
    D421: {
        dioceseId: 'D421',
        diocsesName: 'Reims',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0703',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D422: {
        dioceseId: 'D422',
        diocsesName: 'Laon',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0704',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D423: {
        dioceseId: 'D423',
        diocsesName: 'Amiens',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0705',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D424: {
        dioceseId: 'D424',
        diocsesName: 'Beauvais',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0706',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D425: {
        dioceseId: 'D425',
        diocsesName: 'Châlons-sur-Marne',
        diocsesNameAlt: 'Châlons-en-Champagne',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0707',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D426: {
        dioceseId: 'D426',
        diocsesName: 'Senlis',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0708',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D427: {
        dioceseId: 'D427',
        diocsesName: 'Soissons',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0709',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D428: {
        dioceseId: 'D428',
        diocsesName: 'Noyon',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0710',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'united with Tournai until 1146',
    },
    D429: {
        dioceseId: 'D429',
        diocsesName: 'Thérouanne',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0711',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D430: {
        dioceseId: 'D430',
        diocsesName: 'Cambrai',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0712',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D431: {
        dioceseId: 'D431',
        diocsesName: 'Arras',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0713',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1093',
        yearSuppressed: '',
        notes: 're-established 1093 from Cambrai',
    },
    D432: {
        dioceseId: 'D432',
        diocsesName: 'Tournai',
        diocsesNameAlt: '',
        province: 'Reims',
        province2: '',
        province3: '',
        countryModern: 'Belgium',
        shapeFileId: 'S0714',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'united with Noyon until 1146',
    },
    D433: {
        dioceseId: 'D433',
        diocsesName: 'Rouen',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0715',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D434: {
        dioceseId: 'D434',
        diocsesName: 'Avranches',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0716',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D435: {
        dioceseId: 'D435',
        diocsesName: 'Bayeux',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0717',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D436: {
        dioceseId: 'D436',
        diocsesName: 'Coutances',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0718',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D437: {
        dioceseId: 'D437',
        diocsesName: 'Évreux',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0719',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D438: {
        dioceseId: 'D438',
        diocsesName: 'Lisieux',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0720',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D439: {
        dioceseId: 'D439',
        diocsesName: 'Sees',
        diocsesNameAlt: '',
        province: 'Rouen',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0721',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D440: {
        dioceseId: 'D440',
        diocsesName: 'Tours',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0722',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D441: {
        dioceseId: 'D441',
        diocsesName: 'Angers',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0723',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D442: {
        dioceseId: 'D442',
        diocsesName: 'Le Mans',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0724',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D443: {
        dioceseId: 'D443',
        diocsesName: 'Nantes',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0725',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D444: {
        dioceseId: 'D444',
        diocsesName: 'Dol',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0726',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D445: {
        dioceseId: 'D445',
        diocsesName: 'Quimper',
        diocsesNameAlt: 'Cornouailles',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0727',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D446: {
        dioceseId: 'D446',
        diocsesName: 'Rennes',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0728',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D447: {
        dioceseId: 'D447',
        diocsesName: 'Saint-Brieuc',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0729',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D448: {
        dioceseId: 'D448',
        diocsesName: 'Saint-Malo',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0730',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D449: {
        dioceseId: 'D449',
        diocsesName: 'Saint-Pol-de-Léon',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0731',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D450: {
        dioceseId: 'D450',
        diocsesName: 'Tréguier',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0732',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D451: {
        dioceseId: 'D451',
        diocsesName: 'Vannes',
        diocsesNameAlt: '',
        province: 'Tours',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0733',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D452: {
        dioceseId: 'D452',
        diocsesName: 'Sens',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0734',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D453: {
        dioceseId: 'D453',
        diocsesName: 'Auxerre',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0735',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D454: {
        dioceseId: 'D454',
        diocsesName: 'Nevers',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0736',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D455: {
        dioceseId: 'D455',
        diocsesName: 'Troyes',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0737',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D456: {
        dioceseId: 'D456',
        diocsesName: 'Paris',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0738',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D457: {
        dioceseId: 'D457',
        diocsesName: 'Chartres',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0739',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D458: {
        dioceseId: 'D458',
        diocsesName: 'Meaux',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0740',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D459: {
        dioceseId: 'D459',
        diocsesName: 'Orléans',
        diocsesNameAlt: '',
        province: 'Sens',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0741',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D460: {
        dioceseId: 'D460',
        diocsesName: 'Tarentaise',
        diocsesNameAlt: 'Moutiers-en-Tarentaise',
        province: 'Tarentaise',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0742',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Seat at Moutiers, another name for the city of Tarentaise',
    },
    D461: {
        dioceseId: 'D461',
        diocsesName: 'Aosta',
        diocsesNameAlt: 'Aoste',
        province: 'Tarentaise',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0743',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D462: {
        dioceseId: 'D462',
        diocsesName: 'Sion',
        diocsesNameAlt: 'Sitten',
        province: 'Tarentaise',
        province2: '',
        province3: '',
        countryModern: 'Switzerland',
        shapeFileId: 'S0448',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D463: {
        dioceseId: 'D463',
        diocsesName: 'Vienne',
        diocsesNameAlt: '',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0744',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D464: {
        dioceseId: 'D464',
        diocsesName: 'Die',
        diocsesNameAlt: '',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0745',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D465: {
        dioceseId: 'D465',
        diocsesName: 'Geneva',
        diocsesNameAlt: 'Geneve',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'Switzerland',
        shapeFileId: 'S0746',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D466: {
        dioceseId: 'D466',
        diocsesName: 'Grenoble',
        diocsesNameAlt: '',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0747',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D467: {
        dioceseId: 'D467',
        diocsesName: 'Saint-Jean-de-Maurienne',
        diocsesNameAlt: 'Maurienne',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0748',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D468: {
        dioceseId: 'D468',
        diocsesName: 'Valence',
        diocsesNameAlt: '',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0749',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D469: {
        dioceseId: 'D469',
        diocsesName: 'Viviers',
        diocsesNameAlt: '',
        province: 'Vienne',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0750',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D470: {
        dioceseId: 'D470',
        diocsesName: 'Modruš',
        diocsesNameAlt: 'Modrus, Corbavia, Krbava',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0025',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1185',
        yearSuppressed: '1501',
        notes:
            'see transferred from Krbava to Modruš in 1460; joined with Senj in 1630; alternate names Krbava, Corbavia',
    },
    D471: {
        dioceseId: 'D471',
        diocsesName: 'Senj',
        diocsesNameAlt: 'Segna',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0564',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1185',
        yearSuppressed: '',
        notes: 'joined with Modrus in 1630',
    },
    D472: {
        dioceseId: 'D472',
        diocsesName: 'Krk',
        diocsesNameAlt: 'Veglia',
        province: 'Split',
        province2: 'Zadar',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0026',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'made suffragan to Zadar in 1146',
    },
    D473: {
        dioceseId: 'D473',
        diocsesName: 'Zadar',
        diocsesNameAlt: 'Zara',
        province: 'Zadar',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0027',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D475: {
        dioceseId: 'D475',
        diocsesName: 'Rab',
        diocsesNameAlt: 'Arbe',
        province: 'Zadar',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0028',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D476: {
        dioceseId: 'D476',
        diocsesName: 'Waterford',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0424',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1096',
        yearSuppressed: '1363',
        notes: '',
    },
    D477: {
        dioceseId: 'D477',
        diocsesName: 'Nin',
        diocsesNameAlt: 'Nona',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0030',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: '',
    },
    D478: {
        dioceseId: 'D478',
        diocsesName: 'Knin',
        diocsesNameAlt: 'Tininum',
        province: 'Zadar',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0896',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D479: {
        dioceseId: 'D479',
        diocsesName: 'Skradin',
        diocsesNameAlt: 'Scardona',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0031',
        shapeFileId2: 'S0039',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1126',
        yearSuppressed: '1501',
        notes: '',
    },
    D480: {
        dioceseId: 'D480',
        diocsesName: 'Šibenik',
        diocsesNameAlt: 'Sebenico',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0038',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1298',
        yearSuppressed: '',
        notes: 'boundary with Scardona estimated',
    },
    D481: {
        dioceseId: 'D481',
        diocsesName: 'Trogir',
        diocsesNameAlt: 'Traù',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0032',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1050',
        yearSuppressed: '',
        notes: '',
    },
    D482: {
        dioceseId: 'D482',
        diocsesName: 'Split',
        diocsesNameAlt: 'Spalato',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0033',
        shapeFileId2: 'S0565',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1344',
        notes: 'combined with Makarska',
    },
    D483: {
        dioceseId: 'D483',
        diocsesName: 'Makarska',
        diocsesNameAlt: 'Macarsca',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0034',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1344',
        yearSuppressed: '1400',
        notes: 'combined with Split',
    },
    D484: {
        dioceseId: 'D484',
        diocsesName: 'Lesina',
        diocsesNameAlt: 'Hvar',
        province: 'Zadar',
        province2: 'Split',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0035',
        shapeFileId2: 'S0015',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1145',
        yearSuppressed: '',
        notes: 'made suffragan to Split in 1180',
    },
    D485: {
        dioceseId: 'D485',
        diocsesName: 'Korčula',
        diocsesNameAlt: 'Curzola',
        province: 'Ragusa',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0036',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1300',
        yearSuppressed: '',
        notes:
            'suffragan to Dubrovnik; two islands previously belonged to Diocese of Hvar pre 1300',
    },
    D486: {
        dioceseId: 'D486',
        diocsesName: 'Ston',
        diocsesNameAlt: 'Stagno',
        province: 'Split',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0901',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1300',
        notes:
            'seat moved to Korcula in 1300 (which is when Korcula covers the two islands territory); became autonomous again in 1541',
    },
    D487: {
        dioceseId: 'D487',
        diocsesName: 'Dubrovnik',
        diocsesNameAlt: 'Ragusa',
        province: 'Ragusa',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0037',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D488: {
        dioceseId: 'D488',
        diocsesName: 'Kotor',
        diocsesNameAlt: 'Cattaro',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Montenegro',
        shapeFileId: 'S0040',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D489: {
        dioceseId: 'D489',
        diocsesName: 'Budva',
        diocsesNameAlt: 'Budua',
        province: 'Ragusa',
        province2: '',
        province3: '',
        countryModern: 'Montenegro',
        shapeFileId: 'S0893',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D490: {
        dioceseId: 'D490',
        diocsesName: 'Osor',
        diocsesNameAlt: 'Ossero',
        province: 'Zadar',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0006',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D491: {
        dioceseId: 'D491',
        diocsesName: 'Mariana',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0331',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D492: {
        dioceseId: 'D492',
        diocsesName: 'Nebbio',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0332',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D493: {
        dioceseId: 'D493',
        diocsesName: 'Aleria',
        diocsesNameAlt: '',
        province: 'Pisa',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0333',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D494: {
        dioceseId: 'D494',
        diocsesName: 'Accia',
        diocsesNameAlt: '',
        province: 'Genova',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0334',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D495: {
        dioceseId: 'D495',
        diocsesName: 'Sagone',
        diocsesNameAlt: 'Sagona',
        province: 'Pisa',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0335',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D496: {
        dioceseId: 'D496',
        diocsesName: 'Ajaccio',
        diocsesNameAlt: '',
        province: 'Pisa',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0336',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D497: {
        dioceseId: 'D497',
        diocsesName: 'Holar',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Iceland',
        shapeFileId: 'S0358',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1106',
        yearSuppressed: '',
        notes: '',
    },
    D498: {
        dioceseId: 'D498',
        diocsesName: 'Skálholt',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Iceland',
        shapeFileId: 'S0359',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1056',
        yearSuppressed: '',
        notes: '',
    },
    D499: {
        dioceseId: 'D499',
        diocsesName: 'Raphoe',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0360',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D500: {
        dioceseId: 'D500',
        diocsesName: 'Derry',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0361',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Seat at Maghera until 1254',
    },
    D501: {
        dioceseId: 'D501',
        diocsesName: 'Connor',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0362',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D502: {
        dioceseId: 'D502',
        diocsesName: 'Down',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0363',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D503: {
        dioceseId: 'D503',
        diocsesName: 'Dromore',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0364',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D504: {
        dioceseId: 'D504',
        diocsesName: 'Armagh',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0365',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D505: {
        dioceseId: 'D505',
        diocsesName: 'Clogher',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0366',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D506: {
        dioceseId: 'D506',
        diocsesName: 'Kilmore',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0367',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D507: {
        dioceseId: 'D507',
        diocsesName: 'Ardagh',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0368',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D508: {
        dioceseId: 'D508',
        diocsesName: 'Meath',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0369',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D509: {
        dioceseId: 'D509',
        diocsesName: 'Clonmacnois',
        diocsesNameAlt: '',
        province: 'Armagh',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0370',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D510: {
        dioceseId: 'D510',
        diocsesName: 'Killala',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0371',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D511: {
        dioceseId: 'D511',
        diocsesName: 'Achonry',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0372',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D512: {
        dioceseId: 'D512',
        diocsesName: 'Tuam',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0373',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D513: {
        dioceseId: 'D513',
        diocsesName: 'Elphin',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0374',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D514: {
        dioceseId: 'D514',
        diocsesName: 'Clonfert',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0375',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D515: {
        dioceseId: 'D515',
        diocsesName: 'Annaghdown',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0376',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1179',
        yearSuppressed: '',
        notes: '',
    },
    D516: {
        dioceseId: 'D516',
        diocsesName: 'Kilmacduagh',
        diocsesNameAlt: '',
        province: 'Tuam',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0377',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D517: {
        dioceseId: 'D517',
        diocsesName: 'Kilfenora',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0378',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D518: {
        dioceseId: 'D518',
        diocsesName: 'Killaloe',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0379',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D519: {
        dioceseId: 'D519',
        diocsesName: 'Cashel',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0380',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D520: {
        dioceseId: 'D520',
        diocsesName: 'Emly',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0381',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D521: {
        dioceseId: 'D521',
        diocsesName: 'Limerick',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0382',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D522: {
        dioceseId: 'D522',
        diocsesName: 'Ardfert',
        diocsesNameAlt: 'Kerry',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0383',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D523: {
        dioceseId: 'D523',
        diocsesName: 'Cloyne',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0384',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D524: {
        dioceseId: 'D524',
        diocsesName: 'Lismore',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0426',
        shapeFileId2: 'S0385',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1111',
        yearSuppressed: '',
        notes: 'United with Waterford in 1363',
    },
    D525: {
        dioceseId: 'D525',
        diocsesName: 'Cork',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0386',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D526: {
        dioceseId: 'D526',
        diocsesName: 'Ross',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0387',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D527: {
        dioceseId: 'D527',
        diocsesName: 'Kildare',
        diocsesNameAlt: '',
        province: 'Dublin',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0388',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D528: {
        dioceseId: 'D528',
        diocsesName: 'Dublin',
        diocsesNameAlt: '',
        province: 'Dublin',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0389',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D529: {
        dioceseId: 'D529',
        diocsesName: 'Leighlin',
        diocsesNameAlt: '',
        province: 'Dublin',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0390',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D530: {
        dioceseId: 'D530',
        diocsesName: 'Ossory',
        diocsesNameAlt: '',
        province: 'Dublin',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0391',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D531: {
        dioceseId: 'D531',
        diocsesName: 'Ferns',
        diocsesNameAlt: '',
        province: 'Dublin',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0392',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D532: {
        dioceseId: 'D532',
        diocsesName: 'Bremen-Hamburg',
        diocsesNameAlt: '',
        province: 'Bremen',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0393',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D533: {
        dioceseId: 'D533',
        diocsesName: 'Lübeck',
        diocsesNameAlt: '',
        province: 'Bremen',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0394',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D534: {
        dioceseId: 'D534',
        diocsesName: 'Ratzeburg',
        diocsesNameAlt: '',
        province: 'Bremen',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0395',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1061',
        yearSuppressed: '',
        notes: '',
    },
    D535: {
        dioceseId: 'D535',
        diocsesName: 'Schwerin',
        diocsesNameAlt: '',
        province: 'Bremen',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0396',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D536: {
        dioceseId: 'D536',
        diocsesName: 'Lebus',
        diocsesNameAlt: '',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0397',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1124',
        yearSuppressed: '',
        notes: '',
    },
    D537: {
        dioceseId: 'D537',
        diocsesName: 'Wrocław ',
        diocsesNameAlt: 'Breslau',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0398',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1000',
        yearSuppressed: '',
        notes: '',
    },
    D538: {
        dioceseId: 'D538',
        diocsesName: 'Cologne',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0399',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D539: {
        dioceseId: 'D539',
        diocsesName: 'Liège',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Belgium',
        shapeFileId: 'S0444',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D540: {
        dioceseId: 'D540',
        diocsesName: 'Minden',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0400',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D541: {
        dioceseId: 'D541',
        diocsesName: 'Münster',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0401',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D542: {
        dioceseId: 'D542',
        diocsesName: 'Osnabruck',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0402',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D543: {
        dioceseId: 'D543',
        diocsesName: 'Utrecht',
        diocsesNameAlt: '',
        province: 'Cologne',
        province2: '',
        province3: '',
        countryModern: 'Netherlands',
        shapeFileId: 'S0403',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D544: {
        dioceseId: 'D544',
        diocsesName: 'Magdeburg',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0404',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D545: {
        dioceseId: 'D545',
        diocsesName: 'Brandenburg',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0405',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D546: {
        dioceseId: 'D546',
        diocsesName: 'Havelberg',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0406',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D547: {
        dioceseId: 'D547',
        diocsesName: 'Merseburg',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0407',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D548: {
        dioceseId: 'D548',
        diocsesName: 'Naumburg',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0408',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D549: {
        dioceseId: 'D549',
        diocsesName: 'Mainz',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0409',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D550: {
        dioceseId: 'D550',
        diocsesName: 'Augsburg',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0410',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D551: {
        dioceseId: 'D551',
        diocsesName: 'Chur',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Switzerland',
        shapeFileId: 'S0411',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D552: {
        dioceseId: 'D552',
        diocsesName: 'Eichstätt',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0412',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D553: {
        dioceseId: 'D553',
        diocsesName: 'Halberstadt',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0413',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D554: {
        dioceseId: 'D554',
        diocsesName: 'Hildesheim',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0414',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D555: {
        dioceseId: 'D555',
        diocsesName: 'Konstanz',
        diocsesNameAlt: 'Constance',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0415',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D556: {
        dioceseId: 'D556',
        diocsesName: 'Paderborn',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0416',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D557: {
        dioceseId: 'D557',
        diocsesName: 'Speyer',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0446',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D558: {
        dioceseId: 'D558',
        diocsesName: 'Strasbourg',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0417',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D559: {
        dioceseId: 'D559',
        diocsesName: 'Verden',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0418',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D560: {
        dioceseId: 'D560',
        diocsesName: 'Worms',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0419',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D561: {
        dioceseId: 'D561',
        diocsesName: 'Würzburg',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0420',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D562: {
        dioceseId: 'D562',
        diocsesName: 'Prague',
        diocsesNameAlt: '',
        province: 'Mainz',
        province2: 'Prague',
        province3: '',
        countryModern: 'Czech Republic',
        shapeFileId: 'S0421',
        shapeFileId2: 'S0780',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'suffragan of Mainz until 1344, then elevated to province',
    },
    D563: {
        dioceseId: 'D563',
        diocsesName: 'Olomouc',
        diocsesNameAlt: 'Olmutz',
        province: 'Mainz',
        province2: 'Prague',
        province3: '',
        countryModern: 'Czech Republic',
        shapeFileId: 'S0422',
        shapeFileId2: 'S0810',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1063',
        yearSuppressed: '',
        notes: '',
    },
    D564: {
        dioceseId: 'D564',
        diocsesName: 'Salzburg',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0766',
        shapeFileId2: 'S0767',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D565: {
        dioceseId: 'D565',
        diocsesName: 'Brixen',
        diocsesNameAlt: 'Bressanone',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0603',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D566: {
        dioceseId: 'D566',
        diocsesName: 'Chiemsee',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0604',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1215',
        yearSuppressed: '',
        notes: '',
    },
    D567: {
        dioceseId: 'D567',
        diocsesName: 'Freising',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0605',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D568: {
        dioceseId: 'D568',
        diocsesName: 'Gurk',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0606',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1072',
        yearSuppressed: '',
        notes: '',
    },
    D569: {
        dioceseId: 'D569',
        diocsesName: 'Lavant',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0607',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1225',
        yearSuppressed: '',
        notes: '',
    },
    D570: {
        dioceseId: 'D570',
        diocsesName: 'Passau',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0614',
        shapeFileId2: 'S0608',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D571: {
        dioceseId: 'D571',
        diocsesName: 'Regensburg',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0609',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D572: {
        dioceseId: 'D572',
        diocsesName: 'Seckau',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0610',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1218',
        yearSuppressed: '',
        notes: '',
    },
    D573: {
        dioceseId: 'D573',
        diocsesName: 'Trier',
        diocsesNameAlt: '',
        province: 'Trier',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0428',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D574: {
        dioceseId: 'D574',
        diocsesName: 'Metz',
        diocsesNameAlt: '',
        province: 'Trier',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0429',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D575: {
        dioceseId: 'D575',
        diocsesName: 'Toul',
        diocsesNameAlt: '',
        province: 'Trier',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0430',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D576: {
        dioceseId: 'D576',
        diocsesName: 'Verdun',
        diocsesNameAlt: '',
        province: 'Trier',
        province2: '',
        province3: '',
        countryModern: 'France',
        shapeFileId: 'S0751',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D577: {
        dioceseId: 'D577',
        diocsesName: 'Bamberg',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0432',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1007',
        yearSuppressed: '',
        notes: '',
    },
    D579: {
        dioceseId: 'D579',
        diocsesName: 'Meissen',
        diocsesNameAlt: '',
        province: 'Magdeburg',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0434',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D580: {
        dioceseId: 'D580',
        diocsesName: 'Vienna',
        diocsesNameAlt: '',
        province: 'Vienna',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0611',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1469',
        yearSuppressed: '',
        notes: 'previously part of Passau',
    },
    D581: {
        dioceseId: 'D581',
        diocsesName: 'Wiener Neustadt',
        diocsesNameAlt: '',
        province: 'Wiener Neustadt',
        province2: '',
        province3: '',
        countryModern: 'Austria',
        shapeFileId: 'S0612',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1469',
        yearSuppressed: '',
        notes: 'previously part of Passau',
    },
    D582: {
        dioceseId: 'D582',
        diocsesName: 'Berchtesgaden',
        diocsesNameAlt: '',
        province: 'Salzburg',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0613',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1455',
        yearSuppressed: '',
        notes: 'made exempt provostship 1455, previously part of Salzburg',
    },
    D583: {
        dioceseId: 'D583',
        diocsesName: 'Reval',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: 'Riga',
        province3: '',
        countryModern: 'Estonia',
        shapeFileId: 'S0435',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1219',
        yearSuppressed: '1561',
        notes: 'made suffragan of Riga in 1374',
    },
    D584: {
        dioceseId: 'D584',
        diocsesName: 'Schleswig',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Germany',
        shapeFileId: 'S0436',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D585: {
        dioceseId: 'D585',
        diocsesName: 'Riga',
        diocsesNameAlt: '',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Latvia',
        shapeFileId: 'S0437',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1186',
        yearSuppressed: '',
        notes:
            'erected at Uxkull in 1186; moved to Riga in 1202; elevated to archbishopric in 1245',
    },
    D586: {
        dioceseId: 'D586',
        diocsesName: 'Warmia',
        diocsesNameAlt: 'Ermland',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0438',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1243',
        yearSuppressed: '',
        notes: 'made suffragan of Riga in 1245',
    },
    D587: {
        dioceseId: 'D587',
        diocsesName: 'Dorpat',
        diocsesNameAlt: 'Tartu',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Estonia',
        shapeFileId: 'S0439',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1224',
        yearSuppressed: '1558',
        notes: 'preceded by diocese of Leal',
    },
    D588: {
        dioceseId: 'D588',
        diocsesName: 'Chełmno ',
        diocsesNameAlt: 'Culm',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0440',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1243',
        yearSuppressed: '1992',
        notes: 'made suffragan of Riga in 1245',
    },
    D589: {
        dioceseId: 'D589',
        diocsesName: 'Courland',
        diocsesNameAlt: 'Kurland',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Latvia',
        shapeFileId: 'S0441',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1234',
        yearSuppressed: '',
        notes: '',
    },
    D590: {
        dioceseId: 'D590',
        diocsesName: 'Osel-Wiek',
        diocsesNameAlt: '',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Estonia',
        shapeFileId: 'S0442',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1228',
        yearSuppressed: '1560',
        notes: 'made suffragan of Riga in 1253',
    },
    D591: {
        dioceseId: 'D591',
        diocsesName: 'Pomesania',
        diocsesNameAlt: '',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0443',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1243',
        yearSuppressed: '1524',
        notes:
            'made suffragan of Riga in 1245; vacant from 1524; suppressed in 1763',
    },
    D592: {
        dioceseId: 'D592',
        diocsesName: 'Samland',
        diocsesNameAlt: '',
        province: 'Riga',
        province2: '',
        province3: '',
        countryModern: 'Russia',
        shapeFileId: 'S0445',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1243',
        yearSuppressed: '1525',
        notes: 'made suffragan of Riga in 1245',
    },
    D593: {
        dioceseId: 'D593',
        diocsesName: 'Krakow',
        diocsesNameAlt: '',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0449',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1000',
        yearSuppressed: '',
        notes: '',
    },
    D594: {
        dioceseId: 'D594',
        diocsesName: 'Kujawy',
        diocsesNameAlt: 'Włocławek',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0450',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1015',
        yearSuppressed: '',
        notes: 'also known as Kuyavia; established ca. 1015',
    },
    D595: {
        dioceseId: 'D595',
        diocsesName: 'Poznań',
        diocsesNameAlt: '',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0459',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1075',
        yearSuppressed: '',
        notes: '',
    },
    D596: {
        dioceseId: 'D596',
        diocsesName: 'Płock',
        diocsesNameAlt: '',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0460',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1075',
        yearSuppressed: '',
        notes: '',
    },
    D597: {
        dioceseId: 'D597',
        diocsesName: 'Samogitia',
        diocsesNameAlt: 'Žemaitija',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0577',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1417',
        yearSuppressed: '',
        notes: 'also known as Zemaitija',
    },
    D598: {
        dioceseId: 'D598',
        diocsesName: 'Vilnius',
        diocsesNameAlt: 'Vilna',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Lithuania',
        shapeFileId: 'S0578',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1387',
        yearSuppressed: '',
        notes: '',
    },
    D599: {
        dioceseId: 'D599',
        diocsesName: 'Lund',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0451',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D600: {
        dioceseId: 'D600',
        diocsesName: 'Roskilde',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0452',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D601: {
        dioceseId: 'D601',
        diocsesName: 'Odense',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0453',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D602: {
        dioceseId: 'D602',
        diocsesName: 'Vic',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0531',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '886',
        yearSuppressed: '',
        notes: '',
    },
    D603: {
        dioceseId: 'D603',
        diocsesName: 'Ribe',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0455',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D604: {
        dioceseId: 'D604',
        diocsesName: 'Aarhus',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0456',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D605: {
        dioceseId: 'D605',
        diocsesName: 'Viborg',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0457',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D606: {
        dioceseId: 'D606',
        diocsesName: 'Borglum',
        diocsesNameAlt: '',
        province: 'Lund',
        province2: '',
        province3: '',
        countryModern: 'Denmark',
        shapeFileId: 'S0458',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1060',
        yearSuppressed: '1528',
        notes: '',
    },
    D607: {
        dioceseId: 'D607',
        diocsesName: 'Toledo',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0494',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D608: {
        dioceseId: 'D608',
        diocsesName: 'Palencia',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0495',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1035',
        yearSuppressed: '',
        notes: '',
    },
    D609: {
        dioceseId: 'D609',
        diocsesName: 'Osma',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0496',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1088',
        yearSuppressed: '',
        notes: '',
    },
    D610: {
        dioceseId: 'D610',
        diocsesName: 'Segovia',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0497',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1119',
        yearSuppressed: '',
        notes: '',
    },
    D611: {
        dioceseId: 'D611',
        diocsesName: 'Sigüenza',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0498',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1124',
        yearSuppressed: '',
        notes: '',
    },
    D612: {
        dioceseId: 'D612',
        diocsesName: 'Cuenca',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0499',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1179',
        yearSuppressed: '',
        notes: '',
    },
    D613: {
        dioceseId: 'D613',
        diocsesName: 'Albarracín-Segorbe',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: 'Zaragoza',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0500',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1172',
        yearSuppressed: '1577',
        notes:
            'suffragan of Zaragoza from 1318; united with Segorbe from 1258-1577',
    },
    D614: {
        dioceseId: 'D614',
        diocsesName: 'Baza',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0029',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1492',
        yearSuppressed: '',
        notes:
            'officially restored as a titular see in 1306, but not reconquered until 1492',
    },
    D615: {
        dioceseId: 'D615',
        diocsesName: 'Jaén',
        diocsesNameAlt: 'Baeza',
        province: 'Toledo',
        province2: 'Granada',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0502',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1246',
        yearSuppressed: '',
        notes:
            'suffragan of Granada from 1492; see at Baeza, renamed, 1226-1246',
    },
    D616: {
        dioceseId: 'D616',
        diocsesName: 'Cordoba',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0503',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1236',
        yearSuppressed: '',
        notes: '',
    },
    D617: {
        dioceseId: 'D617',
        diocsesName: 'Braga',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: '',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0504',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D618: {
        dioceseId: 'D618',
        diocsesName: 'Astorga',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Santiago',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0505',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '852',
        yearSuppressed: '',
        notes: 'suffragan of Santiago from 1393',
    },
    D619: {
        dioceseId: 'D619',
        diocsesName: 'Lugo',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Santiago',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0506',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '850',
        yearSuppressed: '',
        notes: 'suffragan of Santiago from 1393',
    },
    D620: {
        dioceseId: 'D620',
        diocsesName: 'Mondonedo',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Santiago',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0507',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '881',
        yearSuppressed: '',
        notes: 'suffragan of Santiago from 1393',
    },
    D621: {
        dioceseId: 'D621',
        diocsesName: 'Orense',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Santiago',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0508',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1071',
        yearSuppressed: '',
        notes:
            'established 881; re-established 1071; made suffragan of Santiago in 1393',
    },
    D622: {
        dioceseId: 'D622',
        diocsesName: 'Tui',
        diocsesNameAlt: 'Tuy',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0509',
        shapeFileId2: 'S0920',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1069',
        yearSuppressed: '',
        notes: '',
    },
    D623: {
        dioceseId: 'D623',
        diocsesName: 'Oporto',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: '',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0510',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1112',
        yearSuppressed: '',
        notes: 'established 881; re-established 1112',
    },
    D624: {
        dioceseId: 'D624',
        diocsesName: 'Coimbra',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: '',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0511',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D625: {
        dioceseId: 'D625',
        diocsesName: 'Viseu',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: '',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0512',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D626: {
        dioceseId: 'D626',
        diocsesName: 'Zamora',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Toledo',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0518',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'suffragan of Toledo (and episodically Santiago) from 1102-1153; of Braga from 1153-1199; much dispute for next two centuries; firmly made suffragan of Santiago in 1394',
    },
    D627: {
        dioceseId: 'D627',
        diocsesName: 'Silves',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Lisbon',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0514',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1253',
        yearSuppressed: '',
        notes:
            'suffragan to Braga until 1253, then Seville until 1394 (contested periodically by Braga); suffragan to Lisbon thereafter',
    },
    D628: {
        dioceseId: 'D628',
        diocsesName: 'Santiago',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0515',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1095',
        yearSuppressed: '',
        notes: '',
    },
    D629: {
        dioceseId: 'D629',
        diocsesName: 'Avila',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0516',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1087',
        yearSuppressed: '',
        notes: '',
    },
    D630: {
        dioceseId: 'D630',
        diocsesName: 'Salamanca',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0517',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1102',
        yearSuppressed: '',
        notes: 'made exempt in 1513 but that lasted only one year',
    },
    D632: {
        dioceseId: 'D632',
        diocsesName: 'Ciudad Rodrigo',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0519',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1168',
        yearSuppressed: '',
        notes: '',
    },
    D633: {
        dioceseId: 'D633',
        diocsesName: 'Coria',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0520',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1142',
        yearSuppressed: '',
        notes: '',
    },
    D634: {
        dioceseId: 'D634',
        diocsesName: 'Plasencia',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0521',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1189',
        yearSuppressed: '',
        notes: '',
    },
    D635: {
        dioceseId: 'D635',
        diocsesName: 'Badajoz',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0522',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1255',
        yearSuppressed: '',
        notes: '',
    },
    D636: {
        dioceseId: 'D636',
        diocsesName: 'Lamego',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: 'Lisbon',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0523',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1147',
        yearSuppressed: '',
        notes: 'made suffragan of Lisbon in 1393',
    },
    D637: {
        dioceseId: 'D637',
        diocsesName: 'Guarda',
        diocsesNameAlt: '',
        province: 'Santiago',
        province2: 'Lisbon',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0524',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1203',
        yearSuppressed: '',
        notes:
            'translated from earlier diocese of Idanha; made suffragan of Lisbon in 1393',
    },
    D638: {
        dioceseId: 'D638',
        diocsesName: 'Lisboa',
        diocsesNameAlt: 'Lisbon',
        province: 'Santiago',
        province2: 'Lisbon',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0525',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1147',
        yearSuppressed: '',
        notes: 'raised to archdiocese in 1393',
    },
    D639: {
        dioceseId: 'D639',
        diocsesName: 'Evora',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: 'Santiago',
        province3: 'Lisbon',
        countryModern: 'Portugal',
        shapeFileId: 'S0526',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1165',
        yearSuppressed: '',
        notes:
            'suffragan of Braga from 1166-1274; to Santiago from 1274-1394; to Lisbon from 1394-1544',
    },
    D640: {
        dioceseId: 'D640',
        diocsesName: 'Tarragona',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0563',
        shapeFileId2: 'S0527',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D641: {
        dioceseId: 'D641',
        diocsesName: 'Urgel',
        diocsesNameAlt: "Seu d'Urgell",
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0528',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D642: {
        dioceseId: 'D642',
        diocsesName: 'Gerona',
        diocsesNameAlt: 'Girona',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0529',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '785',
        yearSuppressed: '',
        notes: '',
    },
    D643: {
        dioceseId: 'D643',
        diocsesName: 'Barcelona',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0530',
        shapeFileId2: 'S0563',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '850',
        yearSuppressed: '',
        notes: '',
    },
    D644: {
        dioceseId: 'D644',
        diocsesName: 'Granada',
        diocsesNameAlt: '',
        province: 'Toledo',
        province2: 'Granada',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0762',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1437',
        yearSuppressed: '',
        notes:
            'raised to archdiocese 1492; presumably suffragan of Toledo beforehand?',
    },
    D645: {
        dioceseId: 'D645',
        diocsesName: 'Tortosa',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0532',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1118',
        yearSuppressed: '',
        notes: 'established 1118; re-established 1148',
    },
    D646: {
        dioceseId: 'D646',
        diocsesName: 'Lerida',
        diocsesNameAlt: 'Lleida',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0533',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1149',
        yearSuppressed: '',
        notes: '',
    },
    D647: {
        dioceseId: 'D647',
        diocsesName: 'Ager',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0534',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1048',
        yearSuppressed: '1873',
        notes:
            'Abbey; exempt from its foundation in 1048; pope orders that it be joined to Lleida in 1187 but abbots retain effective independence',
    },
    D648: {
        dioceseId: 'D648',
        diocsesName: 'Zaragoza',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: 'Zaragoza',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0535',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1118',
        yearSuppressed: '',
        notes: 'raised to archdiocese in 1318',
    },
    D649: {
        dioceseId: 'D649',
        diocsesName: 'Pamplona',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: 'Zaragoza',
        province3: '[Holy See]',
        countryModern: 'Spain',
        shapeFileId: 'S0536',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '829',
        yearSuppressed: '',
        notes: 'suffragan of Zaragoza from 1318; exempt from 1385',
    },
    D650: {
        dioceseId: 'D650',
        diocsesName: 'Huesca',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: 'Zaragoza',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0537',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1096',
        yearSuppressed: '',
        notes: 'suffragan of Zaragoza from 1318',
    },
    D651: {
        dioceseId: 'D651',
        diocsesName: 'Tarazona',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: 'Zaragoza',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0538',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1120',
        yearSuppressed: '',
        notes: 'suffragan of Zaragoza from 1318',
    },
    D652: {
        dioceseId: 'D652',
        diocsesName: 'Calahorra',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0539',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1045',
        yearSuppressed: '1231',
        notes: 'see moved to Santo Domingo de la Calzada after suppression',
    },
    D653: {
        dioceseId: 'D653',
        diocsesName: 'Calahorra-(Santo Domingo de) la Calzada',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: 'Zaragoza',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0540',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1231',
        yearSuppressed: '',
        notes: 'suffragan of Zaragoza from 1318',
    },
    D654: {
        dioceseId: 'D654',
        diocsesName: 'Valencia',
        diocsesNameAlt: '',
        province: 'Tarragona',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0541',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1239',
        yearSuppressed: '',
        notes: 'exempt from 1470; raised to archdiocese in 1492',
    },
    D655: {
        dioceseId: 'D655',
        diocsesName: 'Seville',
        diocsesNameAlt: '',
        province: 'Seville',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0542',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D656: {
        dioceseId: 'D656',
        diocsesName: 'Valença do Minho',
        diocsesNameAlt: '',
        province: 'Braga',
        province2: '',
        province3: '',
        countryModern: 'Portugal',
        shapeFileId: 'S0765',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1385',
        yearSuppressed: '',
        notes:
            'Portuguese part separated from Tui in 1385 as Apostolic Administration',
    },
    D658: {
        dioceseId: 'D658',
        diocsesName: 'Cádiz',
        diocsesNameAlt: '',
        province: 'Seville',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0545',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1268',
        yearSuppressed: '',
        notes:
            "united with Algeciras for the entirety of that diocese's existence, from 1344 to 1364",
    },
    D659: {
        dioceseId: 'D659',
        diocsesName: 'Canarias',
        diocsesNameAlt: 'Rubicon',
        province: 'Seville',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0546',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1351',
        yearSuppressed: '',
        notes:
            'repeatedly suppressed and restored; suffragan of Seville from 1404; seat moved to Las Palmas, Grand Canarias in 1483',
    },
    D660: {
        dioceseId: 'D660',
        diocsesName: 'Burgos',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0547',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1075',
        yearSuppressed: '',
        notes: 'exempt as of 1095; raised to archdiocese in 1574',
    },
    D661: {
        dioceseId: 'D661',
        diocsesName: 'León',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0548',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'exempt as of 1105',
    },
    D662: {
        dioceseId: 'D662',
        diocsesName: 'Oviedo',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0549',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'exempt as of 1105',
    },
    D663: {
        dioceseId: 'D663',
        diocsesName: 'Kammin',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0433',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1140',
        yearSuppressed: '',
        notes: '',
    },
    D664: {
        dioceseId: 'D664',
        diocsesName: 'Waterford and Lismore',
        diocsesNameAlt: '',
        province: 'Cashel',
        province2: '',
        province3: '',
        countryModern: 'Ireland',
        shapeFileId: 'S0425',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1363',
        yearSuppressed: '',
        notes: '',
    },
    D665: {
        dioceseId: 'D665',
        diocsesName: 'Cartagena',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: 'Granada',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0552',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1250',
        yearSuppressed: '',
        notes: 'suffragan of Granada from 1492',
    },
    D666: {
        dioceseId: 'D666',
        diocsesName: 'Marruecos',
        diocsesNameAlt: '',
        province: 'Seville',
        province2: '',
        province3: '',
        countryModern: 'Morocco',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1246',
        yearSuppressed: '',
        notes:
            'made suffragan to Seville in 1353 (probably, though jurisdiction is unclear as see became titular) [not represented on maps, mostly nominal]',
    },
    D667: {
        dioceseId: 'D667',
        diocsesName: 'Esztergom',
        diocsesNameAlt: '',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0474',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1001',
        yearSuppressed: '',
        notes: '',
    },
    D668: {
        dioceseId: 'D668',
        diocsesName: 'Nitra',
        diocsesNameAlt: 'Nyitra',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Slovakia',
        shapeFileId: 'S0475',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1105',
        yearSuppressed: '',
        notes:
            'also known as Neutra; reestablished in 1105 after falling into abeyance following ca. 880 founding',
    },
    D669: {
        dioceseId: 'D669',
        diocsesName: 'Zagreb',
        diocsesNameAlt: '',
        province: 'Kalocsa',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0476',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1093',
        yearSuppressed: '',
        notes: 'suffragan of Kalocsa from 1180',
    },
    D670: {
        dioceseId: 'D670',
        diocsesName: 'Oradea',
        diocsesNameAlt: 'Nagy Varad',
        province: 'Kalocsa',
        province2: '',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: 'S0477',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes:
            'founded at Byhor in 1009, then transferred to Oradea in 1077; no records of boundaries found, not currently represented in map',
    },
    D671: {
        dioceseId: 'D671',
        diocsesName: 'Eger',
        diocsesNameAlt: 'Erlau',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: 'S0478',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes: '',
    },
    D672: {
        dioceseId: 'D672',
        diocsesName: 'Erdély',
        diocsesNameAlt: 'Alba Iulia',
        province: 'Kalocsa',
        province2: '',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: 'S0479',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes:
            'alternate names Karlsburg, Siebenbürgen; renamed Alba Iulia in 1932',
    },
    D673: {
        dioceseId: 'D673',
        diocsesName: 'Györ ',
        diocsesNameAlt: 'Raab',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0480',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes: '',
    },
    D674: {
        dioceseId: 'D674',
        diocsesName: 'Pécs ',
        diocsesNameAlt: 'Fünfkirchen',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0481',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes: '',
    },
    D675: {
        dioceseId: 'D675',
        diocsesName: 'Vác ',
        diocsesNameAlt: 'Waitzen',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0482',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes: '',
    },
    D676: {
        dioceseId: 'D676',
        diocsesName: 'Veszprém ',
        diocsesNameAlt: '',
        province: 'Esztergom',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0483',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1009',
        yearSuppressed: '',
        notes: '',
    },
    D677: {
        dioceseId: 'D677',
        diocsesName: 'Kalocsa-Bacs',
        diocsesNameAlt: 'Kalocsa',
        province: 'Kalocsa',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0484',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1010',
        yearSuppressed: '',
        notes: 'only Kalocsa until 1135',
    },
    D678: {
        dioceseId: 'D678',
        diocsesName: 'Marosvar',
        diocsesNameAlt: 'Csanád',
        province: 'Kalocsa',
        province2: '',
        province3: '',
        countryModern: 'Hungary',
        shapeFileId: 'S0485',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1035',
        yearSuppressed: '',
        notes: '',
    },
    D679: {
        dioceseId: 'D679',
        diocsesName: 'St. Martin',
        diocsesNameAlt: 'Szepes, Szent Márton',
        province: 'Esztergom',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Slovakia',
        shapeFileId: 'S0486',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'founded early 13th century; headed by an exempt provost (of Abbey of St. Martin) rather than a bishop (aside from brief mention of a bishop in 1291); raised to actual diocese (of Szepes/Spis) in 1776',
    },
    D680: {
        dioceseId: 'D680',
        diocsesName: 'Cumania',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1228',
        yearSuppressed: '1241',
        notes:
            'exists relatively briefly, boundaries very unclear [not represented in maps]',
    },
    D681: {
        dioceseId: 'D681',
        diocsesName: 'Siret',
        diocsesNameAlt: 'Sereth',
        province: '[Holy See]',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1371',
        yearSuppressed: '1434',
        notes:
            'suffragan of Lviv from 1412; in decline from 1388 [not enough information, unmapped]',
    },
    D682: {
        dioceseId: 'D682',
        diocsesName: 'Moldovita ',
        diocsesNameAlt: 'Moldawitza ',
        province: 'Lviv',
        province2: '',
        province3: '',
        countryModern: 'Romania',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1418',
        yearSuppressed: '1550',
        notes: '[not enough information, unmapped]',
    },
    D683: {
        dioceseId: 'D683',
        diocsesName: 'Kiev',
        diocsesNameAlt: 'Kyiv',
        province: 'Halyč ',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1321',
        yearSuppressed: '',
        notes:
            'also known as Kijów; suffragan of Halyč from 1367 until 1414, when it becomes suffragan of Lviv [not enough information, unmapped]',
    },
    D684: {
        dioceseId: 'D684',
        diocsesName: 'Zytomierz ',
        diocsesNameAlt: 'Zhytomyr ',
        province: 'Halyč ',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1321',
        yearSuppressed: '',
        notes:
            'suffragan of Halyč from 1367 until 1414, when it becomes suffragan of Lviv [not enough information, unmapped]',
    },
    D685: {
        dioceseId: 'D685',
        diocsesName: 'Wlodzimierz ',
        diocsesNameAlt: 'Vladimir',
        province: 'Halyč ',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1358',
        yearSuppressed: '1425',
        notes: 'see transferred to Lutsk [not enough information, unmapped]',
    },
    D686: {
        dioceseId: 'D686',
        diocsesName: 'Halyč ',
        diocsesNameAlt: 'Galyc',
        province: 'Halyč ',
        province2: '',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: 'S0905',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1361',
        yearSuppressed: '1414',
        notes: 'raised to archdiocese in 1375',
    },
    D687: {
        dioceseId: 'D687',
        diocsesName: 'Lviv',
        diocsesNameAlt: 'Lwow',
        province: '[Holy See]',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: 'S0936',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1358',
        yearSuppressed: '',
        notes: 'raised to archdiocese in 1412',
    },
    D688: {
        dioceseId: 'D688',
        diocsesName: 'Kamieniec ',
        diocsesNameAlt: 'Kamyanets-Podilskyi ',
        province: 'Halyč ',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: 'S0937',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1373',
        yearSuppressed: '',
        notes:
            'founded ca. 1373; suffragan of Halyč from 1367 until 1414, when it becomes suffragan of Lviv [eastern border uncertain]',
    },
    D689: {
        dioceseId: 'D689',
        diocsesName: 'Luck',
        diocsesNameAlt: 'Lutsk',
        province: 'Halyč ',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: 'S0576',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1404',
        yearSuppressed: '',
        notes:
            'founded ca. 1404; incorporates Wlodzimierz in 1425; suffragan of Halyč until 1414, when it becomes suffragan of Lviv',
    },
    D690: {
        dioceseId: 'D690',
        diocsesName: 'Gniezno',
        diocsesNameAlt: 'Gnesen',
        province: 'Gniezno',
        province2: '',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0461',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1000',
        yearSuppressed: '',
        notes: '',
    },
    D691: {
        dioceseId: 'D691',
        diocsesName: 'Oslo',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Norway',
        shapeFileId: 'S0462',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1068',
        yearSuppressed: '1537',
        notes: '',
    },
    D692: {
        dioceseId: 'D692',
        diocsesName: 'Stavanger',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Norway',
        shapeFileId: 'S0463',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1125',
        yearSuppressed: '1537',
        notes: '',
    },
    D693: {
        dioceseId: 'D693',
        diocsesName: 'Bergen',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Norway',
        shapeFileId: 'S0464',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1068',
        yearSuppressed: '1537',
        notes: '',
    },
    D694: {
        dioceseId: 'D694',
        diocsesName: 'Hamar',
        diocsesNameAlt: '',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Norway',
        shapeFileId: 'S0465',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1152',
        yearSuppressed: '1537',
        notes: '',
    },
    D695: {
        dioceseId: 'D695',
        diocsesName: 'Nidaros',
        diocsesNameAlt: 'Trondheim',
        province: 'Nidaros',
        province2: '',
        province3: '',
        countryModern: 'Norway',
        shapeFileId: 'S0466',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1031',
        yearSuppressed: '1537',
        notes: 'founded ca. 1031; raised to archbishopric in 1151',
    },
    D696: {
        dioceseId: 'D696',
        diocsesName: 'Uppsala',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0467',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1130',
        yearSuppressed: '1588',
        notes:
            'founded 1123, then re-established 1130; raised to archbishopric in 1164',
    },
    D697: {
        dioceseId: 'D697',
        diocsesName: 'Vasteras',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0468',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '1527',
        notes: '',
    },
    D698: {
        dioceseId: 'D698',
        diocsesName: 'Strängnäs',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0469',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1129',
        yearSuppressed: '1527',
        notes: '',
    },
    D699: {
        dioceseId: 'D699',
        diocsesName: 'Linköping',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0470',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1100',
        yearSuppressed: '1527',
        notes: '',
    },
    D700: {
        dioceseId: 'D700',
        diocsesName: 'Skara',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0471',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1014',
        yearSuppressed: '1527',
        notes: '',
    },
    D701: {
        dioceseId: 'D701',
        diocsesName: 'Vaxjo',
        diocsesNameAlt: '',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Sweden',
        shapeFileId: 'S0472',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1170',
        yearSuppressed: '1527',
        notes: '',
    },
    D702: {
        dioceseId: 'D702',
        diocsesName: 'Turku',
        diocsesNameAlt: 'Abo',
        province: 'Uppsala',
        province2: '',
        province3: '',
        countryModern: 'Finland',
        shapeFileId: 'S0473',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1276',
        yearSuppressed: '1527',
        notes: '',
    },
    D703: {
        dioceseId: 'D703',
        diocsesName: 'Garðar',
        diocsesNameAlt: 'Gardar',
        province: 'Bremen',
        province2: 'Nidaros',
        province3: '',
        countryModern: 'Greenland',
        shapeFileId: 'S0600',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1124',
        yearSuppressed: '1537',
        notes: 'suffragan of Nidaros from 1152',
    },
    D704: {
        dioceseId: 'D704',
        diocsesName: 'Faroe Islands',
        diocsesNameAlt: '',
        province: 'Bremen',
        province2: 'Lund',
        province3: 'Nidaros',
        countryModern: 'Denmark',
        shapeFileId: 'S0601',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1076',
        yearSuppressed: '1536',
        notes: 'suffragan of Lund from 1104; suffragan of Nidaros from 1152',
    },
    D705: {
        dioceseId: 'D705',
        diocsesName: 'Cittanova',
        diocsesNameAlt: 'Aemona',
        province: 'Aquileia',
        province2: 'Grado',
        province3: 'Venice',
        countryModern: 'Italy',
        shapeFileId: 'S0022',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'subject to Grado from 1272; united with Parenzo from 1434-1448; given in commendam to Patriarch of Grado (then Venezia) from 1448-1465; union dissolved in 1465.',
    },
    D706: {
        dioceseId: 'D706',
        diocsesName: 'Polignano',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0052',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'contested between Bari and Trani from 1089; firmly made suffragan of Bari in 1152',
    },
    D707: {
        dioceseId: 'D707',
        diocsesName: 'Conversano',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0053',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'probably established in 11th century',
    },
    D708: {
        dioceseId: 'D708',
        diocsesName: 'Przemyśl',
        diocsesNameAlt: '',
        province: 'Halyč',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Ukraine',
        shapeFileId: 'S0935',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1218',
        yearSuppressed: '',
        notes:
            'Greek bishops in communion with Rome from 1218 until mid-14th century; Latin bishops appointed to see from 1353 at the latest; transferred to Lviv with the suppression of Halyč in 1414',
    },
    D709: {
        dioceseId: 'D709',
        diocsesName: 'Leuca',
        diocsesNameAlt: 'Alexanum',
        province: 'Otranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0055',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1521',
        notes:
            'also know as Alessano; regular succession of bishops begins in 1283, but diocese established much earlier; suppressed 1518-1521',
    },
    D710: {
        dioceseId: 'D710',
        diocsesName: 'Mottola',
        diocsesNameAlt: '',
        province: 'Taranto',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0056',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1023',
        yearSuppressed: '',
        notes: '',
    },
    D711: {
        dioceseId: 'D711',
        diocsesName: 'Civitate',
        diocsesNameAlt: 'Città',
        province: 'Benevento',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0058',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'united with Lucera from 1439-1471; see translated and name changed to San Severo in 1580',
    },
    D712: {
        dioceseId: 'D712',
        diocsesName: 'Canosa',
        diocsesNameAlt: 'Cerignoli',
        province: 'Bari',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0069',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'archpresbytery, not elevated to diocese until 1819; From 1099 onward, Canosa was an exempt archpresbytery, though the archbishops of Bari simultaneously held the title of bishop of Canosa',
    },
    D713: {
        dioceseId: 'D713',
        diocsesName: 'Salpi',
        diocsesNameAlt: 'Salpe',
        province: 'Bari',
        province2: 'Trani',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0070',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1547',
        notes:
            'also known as Salapia; suppressed to Trani from 1424-1523, then suppressed definitively in 1547',
    },
    D714: {
        dioceseId: 'D714',
        diocsesName: 'Lavello',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0073',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D715: {
        dioceseId: 'D715',
        diocsesName: 'Bari',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0082',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D716: {
        dioceseId: 'D716',
        diocsesName: 'Bitetto',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0083',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'erected sometime between 1025-1089',
    },
    D717: {
        dioceseId: 'D717',
        diocsesName: 'Bitonto',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0084',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D718: {
        dioceseId: 'D718',
        diocsesName: 'Giovinazzo',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0086',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1020',
        yearSuppressed: '',
        notes: '',
    },
    D719: {
        dioceseId: 'D719',
        diocsesName: 'Ruvo',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0087',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D720: {
        dioceseId: 'D720',
        diocsesName: 'Molfetta',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0088',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1070',
        yearSuppressed: '',
        notes: 'made exempt in 1488',
    },
    D721: {
        dioceseId: 'D721',
        diocsesName: 'Minervino',
        diocsesNameAlt: '',
        province: 'Bari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0092',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'apparently contested between Bari and Trani at beginning; firmly made suffragan of Bari in 1152',
    },
    D722: {
        dioceseId: 'D722',
        diocsesName: 'Canne',
        diocsesNameAlt: '',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0093',
        shapeFileId2: 'S0578',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1030',
        yearSuppressed: '',
        notes: 'united with diocese of Nazareth (Barletta) in 1455',
    },
    D723: {
        dioceseId: 'D723',
        diocsesName: 'Guadix',
        diocsesNameAlt: '',
        province: 'Granada',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0763',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1492',
        yearSuppressed: '',
        notes:
            'suffragan of Granada from 1492; associated with ancient see of Baza',
    },
    D724: {
        dioceseId: 'D724',
        diocsesName: 'Ampurias',
        diocsesNameAlt: '',
        province: 'Torres',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0119',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1070',
        yearSuppressed: '',
        notes:
            'see perhaps moved to Castelsardo in the 15th century, a transfer confirmed in 1503',
    },
    D725: {
        dioceseId: 'D725',
        diocsesName: "Sant'Antioco",
        diocsesNameAlt: 'Sulci',
        province: 'Cagliari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0124',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'also known as Tratalias; Transferred to Iglesias in 1503',
    },
    D727: {
        dioceseId: 'D727',
        diocsesName: 'Tratalias',
        diocsesNameAlt: '',
        province: 'Cagliari',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0886',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '1503',
        notes: 'established in early 13th-c',
    },
    D728: {
        dioceseId: 'D728',
        diocsesName: 'Almeria',
        diocsesNameAlt: '',
        province: 'Granada',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0764',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1492',
        yearSuppressed: '',
        notes: 'suffragan of Granada from 1492',
    },
    D729: {
        dioceseId: 'D729',
        diocsesName: 'Roma',
        diocsesNameAlt: '',
        province: 'Roma',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0146 ',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D730: {
        dioceseId: 'D730',
        diocsesName: 'Albano',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0149',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D731: {
        dioceseId: 'D731',
        diocsesName: 'Frascati',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0150',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D732: {
        dioceseId: 'D732',
        diocsesName: 'Lipari',
        diocsesNameAlt: '',
        province: 'Messina',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0168',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1157',
        yearSuppressed: '',
        notes:
            'erected as diocese of Patti and Lipari in 1157; dioceses split in 1399',
    },
    D733: {
        dioceseId: 'D733',
        diocsesName: 'Mdina',
        diocsesNameAlt: 'Malta',
        province: 'Palermo',
        province2: '',
        province3: '',
        countryModern: 'Malta',
        shapeFileId: 'S0178',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'Made suffragan to Palermo 1156; also known as Medina',
    },
    D734: {
        dioceseId: 'D734',
        diocsesName: 'Majorca',
        diocsesNameAlt: 'Mallorca',
        province: 'Tarragona',
        province2: '[Holy See]',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0543',
        shapeFileId2: 'S0513',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'established 1232; suffragan to Tarragona 1229-1232, exempt thereafter ',
    },
    D736: {
        dioceseId: 'D736',
        diocsesName: 'Farfa',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0204',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1586',
        yearSuppressed: '',
        notes:
            'Officially granted exempt status in 1586, but this was held effectively well beforehand',
    },
    D737: {
        dioceseId: 'D737',
        diocsesName: 'Umana',
        diocsesNameAlt: 'Numana',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0205',
        shapeFileId2: 'S0431',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'lost territory in 1240 with erection of diocese of Recanati (but gained city of Osimo from 1247-1264); Recanati was then united to Numana from 1264-1289; united with Ancona in 1422 (and loses its independent existence)',
    },
    D738: {
        dioceseId: 'D738',
        diocsesName: 'Malaga',
        diocsesNameAlt: '',
        province: 'Granada',
        province2: '',
        province3: '',
        countryModern: 'Spain',
        shapeFileId: 'S0554',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1492',
        yearSuppressed: '',
        notes: 'suffragan of Granada from 1492',
    },
    D742: {
        dioceseId: 'D742',
        diocsesName: 'Nazareth',
        diocsesNameAlt: 'Barletta',
        province: 'Trani',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0555',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'also known as Nazaret; seat moved to Barletta ca. 1350; joined with Canne in 1455',
    },
    D744: {
        dioceseId: 'D744',
        diocsesName: 'Corneto',
        diocsesNameAlt: 'Tarquinia',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: 'S0590',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1435',
        yearSuppressed: '',
        notes:
            'Territory restored from Diocese of Tuscania/Tuscanella, to which it had been suppressed in 510',
    },
    D745: {
        dioceseId: 'D745',
        diocsesName: 'Atri',
        diocsesNameAlt: '',
        province: '[Holy See]',
        province2: '',
        province3: '',
        countryModern: 'Italy',
        shapeFileId: '',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1251',
        yearSuppressed: '1986',
        notes:
            'Territory taken from Diocese of Penne, in union (represented together)',
    },
    D746: {
        dioceseId: 'D746',
        diocsesName: 'Nicosia',
        diocsesNameAlt: '',
        province: 'Nicosia',
        province2: '',
        province3: '',
        countryModern: 'Cyprus',
        shapeFileId: 'S0567',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1196',
        yearSuppressed: '1571',
        notes: '',
    },
    D747: {
        dioceseId: 'D747',
        diocsesName: 'Famagusta',
        diocsesNameAlt: '',
        province: 'Nicosia',
        province2: '',
        province3: '',
        countryModern: 'Cyprus',
        shapeFileId: 'S0568',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1196',
        yearSuppressed: '1571',
        notes: '',
    },
    D748: {
        dioceseId: 'D748',
        diocsesName: 'Limassol',
        diocsesNameAlt: '',
        province: 'Nicosia',
        province2: '',
        province3: '',
        countryModern: 'Cyprus',
        shapeFileId: 'S0569',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1196',
        yearSuppressed: '1571',
        notes: '',
    },
    D749: {
        dioceseId: 'D749',
        diocsesName: 'Paphos',
        diocsesNameAlt: '',
        province: 'Nicosia',
        province2: '',
        province3: '',
        countryModern: 'Cyprus',
        shapeFileId: 'S0570',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1196',
        yearSuppressed: '1571',
        notes: '',
    },
    D750: {
        dioceseId: 'D750',
        diocsesName: 'Litomyšl',
        diocsesNameAlt: '',
        province: 'Prague',
        province2: '',
        province3: '',
        countryModern: 'Czech Republic',
        shapeFileId: 'S0550',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1344',
        yearSuppressed: '1474',
        notes:
            'Erected from Prague then suppressed to it [not enough information, unmapped]',
    },
    D751: {
        dioceseId: 'D751',
        diocsesName: 'Bar',
        diocsesNameAlt: '',
        province: 'Bar',
        province2: '',
        province3: '',
        countryModern: 'Montenegro',
        shapeFileId: 'S0891',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D752: {
        dioceseId: 'D752',
        diocsesName: 'Bosnia',
        diocsesNameAlt: '',
        province: 'Ragusa',
        province2: 'Kalocsa',
        province3: '',
        countryModern: 'Bosnia',
        shapeFileId: 'S0892',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: 'suffragan to Kalocsa after 1300',
    },
    D755: {
        dioceseId: 'D755',
        diocsesName: 'Duvno',
        diocsesNameAlt: '',
        province: 'Split ',
        province2: '',
        province3: '',
        countryModern: 'Bosnia',
        shapeFileId: 'S0895',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '',
    },
    D756: {
        dioceseId: 'D756',
        diocsesName: 'Trebinje',
        diocsesNameAlt: '',
        province: 'Dubrovnik',
        province2: '',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0902',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes: '1361-1391 seat located on island of Mrkan',
    },
    D757: {
        dioceseId: 'D757',
        diocsesName: 'Ulcinj',
        diocsesNameAlt: '',
        province: 'Bar',
        province2: 'Dubrovnik',
        province3: '',
        countryModern: 'Croatia',
        shapeFileId: 'S0903',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '',
        yearSuppressed: '',
        notes:
            'Suffragan to Bar/Antivari; briefly becomes suffragan of Ragusa/Dubrovnik in 1240s, but returns to Bar ca. 1249-1250',
    },
    D758: {
        dioceseId: 'D758',
        diocsesName: 'Chełm',
        diocsesNameAlt: '',
        province: 'Halyč',
        province2: 'Lviv',
        province3: '',
        countryModern: 'Poland',
        shapeFileId: 'S0940',
        shapeFileId2: '',
        shapeFileId3: '',
        shapeFileId4: '',
        yearFounded: '1375',
        yearSuppressed: '',
        notes: 'briefly preceded by Diocese of Łuków (1254-57)',
    },
};
