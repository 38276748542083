/**
 * Diocese/Province IDs that appear in PhiloLogic database
 * (Generated from metadata csv file using a script. See /scripts folder README.)
 */
export const KNOWN_IDS_IN_DATABASE = new Set([
    'D004',
    'D018',
    'D019',
    'D021',
    'D035',
    'D042',
    'D058',
    'D076',
    'D086',
    'D088',
    'D093',
    'D094',
    'D096',
    'D109',
    'D111',
    'D116',
    'D127',
    'D140',
    'D143',
    'D156',
    'D159',
    'D166',
    'D175',
    'D179',
    'D182',
    'D184',
    'D186',
    'D187',
    'D195',
    'D199',
    'D202',
    'D212',
    'D216',
    'D224',
    'D235',
    'D236',
    'D240',
    'D255',
    'D260',
    'D261',
    'D264',
    'D271',
    'D283',
    'D292',
    'D295',
    'D297',
    'D298',
    'D299',
    'D300',
    'D301',
    'D302',
    'D303',
    'D304',
    'D305',
    'D306',
    'D307',
    'D308',
    'D309',
    'D310',
    'D311',
    'D312',
    'D316',
    'D317',
    'D329',
    'D330',
    'D335',
    'D337',
    'D342',
    'D350',
    'D353',
    'D359',
    'D362',
    'D365',
    'D371',
    'D374',
    'D375',
    'D376',
    'D378',
    'D379',
    'D381',
    'D383',
    'D384',
    'D385',
    'D388',
    'D395',
    'D396',
    'D398',
    'D400',
    'D402',
    'D403',
    'D404',
    'D407',
    'D408',
    'D409',
    'D416',
    'D421',
    'D425',
    'D427',
    'D428',
    'D430',
    'D431',
    'D432',
    'D433',
    'D435',
    'D436',
    'D438',
    'D439',
    'D440',
    'D441',
    'D442',
    'D443',
    'D444',
    'D445',
    'D447',
    'D448',
    'D450',
    'D454',
    'D455',
    'D456',
    'D457',
    'D458',
    'D459',
    'D461',
    'D462',
    'D465',
    'D498',
    'D505',
    'D508',
    'D530',
    'D531',
    'D537',
    'D538',
    'D539',
    'D540',
    'D541',
    'D543',
    'D548',
    'D549',
    'D550',
    'D552',
    'D555',
    'D558',
    'D559',
    'D561',
    'D562',
    'D563',
    'D564',
    'D565',
    'D568',
    'D570',
    'D571',
    'D573',
    'D574',
    'D575',
    'D577',
    'D578',
    'D579',
    'D596',
    'D607',
    'D608',
    'D609',
    'D610',
    'D612',
    'D613',
    'D617',
    'D620',
    'D621',
    'D623',
    'D624',
    'D626',
    'D628',
    'D629',
    'D630',
    'D631',
    'D635',
    'D636',
    'D638',
    'D639',
    'D641',
    'D643',
    'D645',
    'D646',
    'D649',
    'D653',
    'D654',
    'D660',
    'D661',
    'D662',
    'D665',
    'D667',
    'D696',
    'D698',
    'D699',
    'D700',
    'D702',
    'D746',
    'P003',
    'P004',
    'P005',
    'P006',
    'P007',
    'P008',
    'P013',
    'P014',
    'P015',
    'P017',
    'P019',
    'P020',
    'P022',
    'P023',
    'P026',
    'P027',
    'P028',
    'P031',
    'P032',
    'P033',
    'P036',
    'P039',
    'P041',
    'P042',
    'P043',
    'P047',
    'P050',
    'P051',
    'P052',
    'P053',
    'P055',
    'P057',
    'P059',
    'P061',
    'P062',
    'P065',
    'P067',
    'P069',
    'P070',
    'P077',
    'P078',
    'P079',
    'P080',
    'P081',
    'P083',
    'P086',
    'P089',
    'P091',
    'P093',
    'P095',
    'R005',
    'R007',
]);
